<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：bom导入组件
	开始时间：2021-01-14
	开发人员：姚雨宏,刘巍骏
	最后修改：2021-05-01
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="tabItem_contentBox billing_table">
    <el-dialog title="bom导入" :visible.sync="showBomExportBox" width="70%" top="10vh" :before-close="handleClose"
      append-to-body>
      <!-- 内容 -->
      <div class="proImportBox" id="bomImportBox">
        <el-tabs type="border-card" v-model="proImportTabIndex">
          <el-tab-pane label="bom导入" name="0">
            <div class="proImportBoxItem">
              <!-- 头部操作提示信息 -->
              <div class="ItemHead">
                <el-row>
                  <el-col :span="3">
                    <el-upload class="upload-demo" :action="fileUpload" :before-upload="beforeUplod"
                      :on-success="uploadSuccess" :file-list="fileList" :show-file-list="false" :headers="headers">
                      <el-button size="small" type="primary" @click="getExportPattern(0)">单个导入</el-button>
                    </el-upload>
                  </el-col>
                  <el-col :span="9">
                    <el-upload class="upload-demo" :action="fileUpload" :before-upload="beforeUplod"
                      :on-success="uploadSuccess" :file-list="fileList" :show-file-list="false" :headers="headers">
                      <el-button size="small" type="primary" @click="getExportPattern(1)">批量导入</el-button>
                    </el-upload>
                  </el-col>
                  </el-col>
                  <el-col :span="12">
                    <el-link target="_blank" :href="url_single" :underline="false">
                      <el-button type="primary" size="small">单个导入样例</el-button>
                    </el-link>
                    <el-link target="_blank" :href="url_multi" :underline="false">
                      <el-button type="primary" size="small">批量导入样例</el-button>
                    </el-link>
                    <span data-v-553c45c8>(最多上传2000行数据，支持xls,xlsx)</span>
                  </el-col>
                </el-row>
                <el-row>
                  <el-col :span="24">
                    <p class="tips">
                      上传文件的表头命名以及位置必须按照样例，带*的为必填项规格名称必须为已经存在的规格，否则将不写入规格信息
                      产品编号与产品名称是唯一的,不能重复的产品分类、产品单位，使用名称进行匹配</p>
                  </el-col>
                </el-row>
              </div>
              <!-- 表格内容 -->
              <div class="ItemBody billing_table">
                <el-table :data="tableData" border style="width: 100%">
                  <el-table-column prop="col" label="列数" sortable width="80"></el-table-column>
                  <el-table-column prop="name" label="列名" width="400"></el-table-column>
                  <el-table-column prop="require" label="必填" width="80"></el-table-column>
                  <el-table-column prop="remark" label="备注说明"></el-table-column>
                </el-table>
              </div>
            </div>
          </el-tab-pane>
          <el-tab-pane label="导入记录" name="1">
            <div class="proImportBoxItem">
              <!-- 表格数据 -->
              <div class="proRecordTable billing_table">
                <el-table :data="recordData" border style="width: 100%">
                  <el-table-column type="index" width="50"></el-table-column>
                  <el-table-column prop="file_path" label="源文件" width="60" show-overflow-tooltip>
                    <template slot-scope="scope">
                      <i class="el-icon-view operate_icon" @click="showFilePreView(scope.row)"></i>
                    </template>
                  </el-table-column>
                  <el-table-column prop="file_name" label="文件名" width="240" show-overflow-tooltip></el-table-column>
                  <el-table-column prop="upload_time" label="上传时间" sortable width="200"></el-table-column>
                  <el-table-column prop="export_pattern" label="方式" width="60">
                    <template slot-scope="scope">
                      <span v-if="scope.row.export_pattern == 0">单个</span>
                      <span v-if="scope.row.export_pattern == 1">批量</span>
                    </template>
                  </el-table-column>
                  <el-table-column prop="status" label="状态" width="120">
                    <template slot-scope="scope">
                      <el-button v-if="scope.row.status == -1" size="mini" type="primary">处理中</el-button>
                      <el-button v-if="scope.row.status == 0" size="mini" type="danger">未处理</el-button>
                      <el-button v-if="scope.row.status == 1" size="mini" type="success">已处理</el-button>
                    </template>
                  </el-table-column>
                  <el-table-column label="处理结果">
                    <template slot-scope="scope">
                      <el-button type="primary" size="mini" @click="showImportDetailBox(scope.row)">查看</el-button>
                    </template>
                  </el-table-column>
                </el-table>
              </div>

              <!-- 分页数据 -->
              <div class="proRecordPage">
                <el-pagination @size-change="recoredSizeChange" @current-change="recordCurrentChange"
                  :current-page="importRecordPageData.currentPage" :page-sizes="[10, 20, 30, 40 ,50]"
                  :page-size="importRecordPageData.pageSize" layout="total, sizes, prev, pager, next, jumper"
                  :total="importRecordPageData.totalPage">
                </el-pagination>
              </div>
            </div>
          </el-tab-pane>
        </el-tabs>
      </div>

      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="small" @click="handleClose">确 定</el-button>
      </span>
    </el-dialog>

    <!-- bom导入详情弹框 -->
    <el-dialog title="bom导入详情" :visible.sync="DetailsBox" width="60%" :before-close="closeDetails" append-to-body>
      <!-- 导入详情总框 -->
      <div class="importDetailBox" id="bomimpoetDetailBox">
        <!-- 导入总结果 -->
        <div class="detailResult">
          <h5>导入结果</h5>
          <p>
            成功条数为:<span>{{resultData.successNum}}</span>
            失败条数为:<span>{{resultData.errorNum}}</span>
            名称重复条数为:<span>{{resultData.nameRepetitionNum}}</span>
            编号重复条数为:<span>{{resultData.codeRepetitionNum}}</span>
            总导入条数:<span>{{resultData.totalNum}}</span>
          </p>
        </div>
        <!-- 导入结果列表 -->
        <div class="detailTable">
          <h5>不合法数据列表</h5>
          <div class="detailTableWapper billing_table">
            <el-table :data="viewDetails" style="width: 100%">
              <el-table-column prop="row" label="行数" sortable width="180"></el-table-column>
              <el-table-column prop="reason" label="失败原因" width="180"></el-table-column>
              <el-table-column prop="record_info" label="记录详情"></el-table-column>
            </el-table>
          </div>
          <!-- 表格分页 -->
          <div class="detailTablePage">
            <el-pagination @size-change="detailSizeChange" @current-change="detailCurrentChange"
              :current-page="detailPageData.currentPage" :page-sizes="[10, 20, 30, 40 ,50]"
              :page-size="detailPageData.pageSize" layout="total, sizes, prev, pager, next, jumper"
              :total="detailPageData.totalPage">
            </el-pagination>
          </div>
        </div>
      </div>
    </el-dialog>

    <!-- 附件预览组件 -->
    <filePreview :previewFilePath="previewFilePath"></filePreview>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入请求
  import api from "@/network/production/production"; //生产相关接口

  //导入组件
  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件

  export default {
    name: 'export_bom',
    props: {
      //控制弹窗信息
      showBomExportBox: {
        type: Boolean,
        default: false
      }
    },
    data() {
      return {
        //界面数据
        proImportTabIndex: '0', //默认产品导入页面
        recordData: [], //产品导入记录
        viewDetails: [], //产品导入详情
        currentDetailId: -1, //当前查询的导入详情id
        exportPattern: 0, //导入模式(0:单个导入 1:批量导入)

        //文件相关
        fileUpload: '', //文件上传方法
        fileUrl: '', //文件下载地址
        filePath: '', //获取文件上传后返回的路径
        fileName: '', //获取文件上传后返回的文件名
        fileList: [], //上传文件回显的信息
        headers: { //上传模板需要用到的token
          token: '',
        },
        url_single: baseUrl + '/upload/system/importMould/bom/bomImportMould_single.xlsx', //样例下载(单个)
        url_multi: baseUrl + '/upload/system/importMould/bom/bomImportMould_multi.xlsx', //样例下载(多个)
        previewFilePath: '', //附件预览路径

        //控制开关
        DetailsBox: false, //产品导入详情弹窗

        //分页相关信息
        importRecordPageData: { //bom导入列表
          currentPage: 1,
          pageSize: 10,
          totalPage: 0
        },
        detailPageData: { //bom导入详情
          currentPage: 1,
          pageSize: 10,
          totalPage: 0
        },

        //详情相关
        resultData: {
          successNum: 0, //成功条数
          errorNum: 0, //失败条数
          nameRepetitionNum: 0, //名称重复条数
          codeRepetitionNum: 0, //编号重复条数
          totalNum: 0, //导入总条数
        }
      }
    },
    watch: {
      //监听tab页发生改变
      proImportTabIndex(newVal) {
        //判断切换的为哪个tab页
        if (newVal == "1") { //导入记录
          //获取导入记录列表
          this.getImportRecodeList();
        }
      }
    },
    created() {
      //初始化表格提示信息
      this.initTableTipData();
      //初始化文件上传相关信息
      this.initFileData();
    },
    mounted() {},
    methods: {
      ...mapMutations([
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),

      /* 初始化文件上传相关信息 */
      initFileData() {
        //bom导入文件上传方法
        this.fileUpload = baseUrl + "/mes/addBomImportFile";
        //bom样例下载地址
        this.fileUrl = baseUrl + "/upload/importproduct/378/20210514203926.xlsx";
        //获取token
        this.headers.token = getCookie('token');
      },

      /* 文件上传之前执行的方法 */
      beforeUplod() {
        //加载loading框(成功后关闭)
        this.loading = this.commonJsExtend.customLoading("#bomImportBox", 4, '文件上传中,请稍候...');
      },

      /* 文件上传成功后执行 */
      uploadSuccess(response, file, fileList) {
        //关闭loading框
        this.loading.close();
        //判断返回结果
        if (response.code == 200) {
          //获取文件相关信息
          this.filePath = response.data; //获取文件上传后的路径
          this.fileName = file.name; //获取文件上传的名称
          //开始执行导入的文件
          let data = {
            fileName: file.name, //文件名称
            filePath: response.data, //文件路径
            exportPattern: this.exportPattern, //导入模式
          }
          this.loading = this.commonJsExtend.customLoading("#bomImportBox", 4, '文件上传成功,正在添加导入任务,请稍候...');
          //发送请求
          api.importBomByFile(data).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '任务添加成功,后台任务执行需要一定时间,请您耐心等待!',
                duration: 3000
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else {
          this.$message({
            type: 'error',
            message: response.message,
            duration: this.elDuration
          })
        }
      },

      /* 获取导入任务列表 */
      getImportRecodeList() {
        let data = {
          pageIndex: this.importRecordPageData.currentPage,
          pageSize: this.importRecordPageData.pageSize,
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#bomimpoetDetailBox", 4, '列表信息获取中,请稍候...');
        //发送请求
        api.findImportBomList(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //获取记录列表
            this.recordData = res.data.rows;
            //获取总记录数
            this.importRecordPageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 导入记录页面大小发生改变 */
      recoredSizeChange(val) {
        //获取页面大小
        this.importRecordPageData.pageSize = val;
        //获取数据
        this.getImportRecodeList();
      },

      /* 导入记录当前页发生改变 */
      recordCurrentChange(val) {
        //获取当前页
        this.importRecordPageData.currentPage = val;
        //获取数据
        this.getImportRecodeList();
      },

      /* 显示导入详情弹框 */
      showImportDetailBox(data) {
        //获取当前选择的id
        this.currentDetailId = data.id;
        //显示弹框
        this.DetailsBox = true;
        //获取导入结果
        this.getImportResult();
        //获取错误列表信息
        this.getDetailList();
      },

      /* 获取导入任务结果 */
      getImportResult() {
        let data = {
          id: this.currentDetailId
        }
        api.getImportBomResult(data).then(res => {
          if (res.code == 200) {
            this.resultData = {
              successNum: res.data.success_num, //成功条数
              errorNum: res.data.other_fail, //失败条数
              nameRepetitionNum: res.data.name_repeat, //名称重复条数
              codeRepetitionNum: res.data.code_repeat, //编号重复条数
              totalNum: res.data.total_num, //导入总条数
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 执行查看导入详情 */
      getDetailList() {
        //定义传入后端的数据
        let data = {
          id: this.currentDetailId,
          pageIndex: this.detailPageData.currentPage,
          pageSize: this.detailPageData.pageSize,
        }
        //根据id查询详情
        api.findImportBomErrors(data).then(res => {
          if (res.code == 200) {
            //获取详情列表
            this.viewDetails = res.data.rows;
            //获取分页数据
            this.detailPageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取导入模式 */
      getExportPattern(type) {
        this.exportPattern = type;
      },

      /* 显示附件预览弹框 */
      showFilePreView(data) {
        //获取预览路径
        this.previewFilePath = data.file_name + "@@@" + data.file_path;
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
      },

      /* 获取导入详情页面大小 */
      detailSizeChange(val) {
        //获取页面大小
        this.detailPageData.pageSize = val;
        //获取数据
        this.getDetailList();
      },

      /* 获取导入详情当前页大小 */
      detailCurrentChange(val) {
        //获取当前页
        this.detailPageData.currentPage = val;
        //获取数据
        this.getDetailList();
      },

      /* 初始化表格提示信息 */
      initTableTipData() {
        //表格信息
        this.tableData = [{
            col: '1',
            name: '货品编号*',
            require: '是'
          }, {
            col: '2',
            name: '物料编号*',
            require: '是'
          }, {
            col: '3',
            name: '父级物料*',
            require: '是'
          }, {
            col: '4',
            name: '子级物料',
            require: '否'
          },
          {
            col: '5',
            name: '物料规格',
            require: '否'
          }, {
            col: '6',
            name: '所需数量*',
            require: '是'
          }, {
            col: '7',
            name: '报损率',
            require: '否'
          }, {
            col: '8',
            name: '成本单价',
            require: '否'
          },
          {
            col: '9',
            name: '所需总费用',
            require: '否'
          }, {
            col: '10',
            name: '备注',
            require: '否'
          },
        ]
      },

      /* 关闭bom导入弹框*/
      handleClose() {
        this.$parent.closeImportBomBox();
      },

      /* 关闭bom详情弹窗 */
      closeDetails() {
        this.DetailsBox = false;
      },
    },
    components:{
      filePreview
    }
  }
</script>

<!-- element-ui自定义样式 -->
<style>
  .proImportBox .el-row {
    padding: 5px;
  }
</style>
<style lang="less">
  .tabItem_contentBox {
    height: 100%;
  }

  .result {
    font-weight: bold;
  }

  /* 导入弹框样式 */
  .proImportBox {
    height: 60vh;

    // border: 1px solid black;
    // 导入弹框盒子
    .proImportBoxItem {
      height: calc(60vh - 80px);
      border: 1px solid @borderColor;

      //导入头部提示信息
      .ItemHead {
        width: 100%;
        height: 100px;
        // border: 1px solid black;
      }

      //导入表格提示字段信息
      .ItemBody {
        width: 100%;
        height: calc(100% - 100px);
        overflow: auto;
        // border: 1px solid black;
      }

      //导入记录表格框
      .proRecordTable {
        height: calc(100% - 40px);
        border: 1px solid @borderColor;
      }

      // 导入记录分页框
      .proRecordPage {
        height: 40px;
        line-height: 40px;
        text-align: right;
        // border: 1px solid @borderColor;
      }
    }
  }

  //导入详情弹框样式
  .importDetailBox {
    height: 55vh;

    // border: 1px solid black;
    h5 {
      width: calc(100% - 20px);
      padding: 8px 10px;
      background: @form_bgColer;
      // border: 1px solid black;
    }

    //导入结果
    .detailResult {
      height: 65px;

      // border: 1px solid black;
      p {
        padding: 5px 0;
      }

      span {
        color: red;
      }
    }

    //详情表格
    .detailTable {
      height: calc(100% - 70px);
      border: 1px solid @borderColor;

      //表格外框
      .detailTableWapper {
        height: calc(100% - 90px);
        margin-top: 10px;
        overflow: auto;
        border: 1px solid @borderColor;
      }

      //表格分页
      .detailTablePage {
        height: 40px;
        // border: 1px solid yellow;
      }
    }
  }
</style>
