<template>
  <div class="technologyFileAll">
    <el-dialog title="工艺标准" :visible.sync="show_technologyfile" width="400px" append-to-body>
      <el-form ref="form" :model="sizeForm" label-width="100px" size="mini">
        <el-form-item label="备注:">
          <el-input type="textarea" :rows="3" placeholder="请输入内容" v-model="textarea"></el-input>
        </el-form-item>
        <el-form-item label="上传文件:">
          <el-upload class="upload-demo" :action="fileUpload" :before-upload="beforeUplod" :before-remove="removeFile"
            :on-success="uploadSuccess" :file-list="fileList" :headers="headers" :on-preview="previewDrawing">
            <el-button size="small" type="primary">点击上传</el-button>
            <div slot="tip" class="el-upload__tip">只能上传jpg/png文件，且不超过500kb</div>
          </el-upload>
        </el-form-item>
      </el-form>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_technologyfile">确认</el-button>
        <el-button size="mini" @click="cancel_technologyfile">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 附件预览组件 -->
     <filePreview :previewFilePath="previewFilePath"></filePreview>
  </div>
</template>

<script>
// 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件
  export default{
    name:"",
    props:{
      show_technologyfile:{
        type:Boolean,
        default:false
      }
    },
    data(){
      
      return{
			textarea:'',
		  sizeForm:{},

      //文件相关
      fileUpload: '', //文件上传方法
      fileUrl: '', //文件下载地址
      filePath: '', //获取文件上传后返回的路径
      fileName: '', //获取文件上传后返回的文件名
      fileList: [], //上传文件回显的信息
      headers: { //上传模板需要用到的token
        token: '',
      },
      ImgUrlArr:[],

       //预览文件
        previewFilePath: '', //附件预览路径
      }
    },
    components:{
        filePreview
    },
    mounted(){
      //初始化文件上传相关信息
      this.initFileData()
    },
    methods:{
      ...mapMutations([
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),
      // 文件提交
      commit_technologyfile(){
         let data={
           file_url:this.filePath,//上传文件路劲
           file_name:this.fileName,//上传文件名称
           remark:this.textarea,//备注
         }
         //将数据传递到父组件
        this.$emit("SelectedData",data);
        this.$parent.hide_technologyfileBox();
      },

      // 文件取消
      cancel_technologyfile(){
        this.ImgUrlArr = [];
        this.fileList = [];
        this.$parent.hide_technologyfileBox();
      },

      /* 初始化文件上传相关信息 */
      initFileData() {
        //bom导入文件上传方法
        this.fileUpload = baseUrl + "/mes/addMesMainTechFile";
        // //bom样例下载地址
        // this.fileUrl = baseUrl + "/upload/importproduct/378/20210514203926.xlsx";
        //获取token
        this.headers.token = getCookie('token');
      },

      /* 文件上传之前执行的方法 */
      beforeUplod() {
        //加载loading框(成功后关闭)
        this.loading = this.commonJsExtend.customLoading("#bomImportBox", 4, '文件上传中,请稍候...');
      },
      /* 文件上传成功后执行 */
      uploadSuccess(response, file, fileList) {
        //关闭loading框
        this.loading.close();
        //判断返回结果
        if (response.code == 200) {
          //获取文件相关信息
          this.filePath = response.data; //获取文件上传后的路径
          this.fileName = file.name; //获取文件上传的名称
          let img={};
          img.name=file.name
          img.url=response.data
          this.fileList.push(img)   
          this.ImgUrlArr.push(response.data)
         }

       },

       /* 删除图片 */
      removeFile(file, fileList) {
        //删除传递到后端数组中的数据
        this.ImgUrlArr.forEach((item, index) => {
          if (file.hasOwnProperty("url")) {
            let url = item.substr(0, item.indexOf("@"))
            let imgurl = baseUrl + url
            if (imgurl == file.url) {
              this.ImgUrlArr.splice(index, 1)
            }
          } else {
            if (item == file.response.data) {
              this.ImgUrlArr.splice(index, 1)
            }
          }
        })
      },

        //上传文件点击查看
       previewDrawing(file){
        if(!!file.response){
          //获取预览路径
          this.previewFilePath = file.name + "@@@" + file.response.data;
        }
        else if(!!file.url){
         let url= file.url.split("//upload")
         let urlValue="//upload"+url[1]
         this.previewFilePath = file.name + "@@@" + urlValue;
        }
        console.log(this.previewFilePath)
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
      },

      handlePreview(){

      },
      handleRemove(){

      },
      beforeRemove(){

      },
    }
  }
</script>

<style>
</style>
