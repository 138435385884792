<!--
  程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：Bom管理视图页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="billingview returnview" id="bomManage_tableBox">
    <!-- 报价操作框 -->
    <div class="billingview_headoperate">
      <!-- 标题 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="getListDataByCondition(0)" :class="{'selLi':sel_searchBtn==0}">
            全部
          </li>
          <li @click="getListDataByCondition(1)" :class="{'selLi':sel_searchBtn==1}">
            已审
          </li>
          <li @click="getListDataByCondition(2)" :class="{'selLi':sel_searchBtn==2}">
            未审
          </li>
          <li @click="getListDataByCondition(3)" :class="{'selLi':sel_searchBtn==3}">
            已设工序
          </li>
          <li>
            <el-popover placement="bottom" width="400" v-model="show_searchBox" trigger="manual">
              <el-form ref="form" :model="offer_searchData" size="mini" label-width="100px">
                <el-form-item>
                  <div class="billingview_searchTitle">
                    <span>根据以下条件搜索:</span>
                  </div>
                </el-form-item>
                <el-form-item label="货品编号:">
                  <el-input v-model="offer_searchData.econding" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="货品名称:">
                  <el-input v-model="offer_searchData.name" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="货品规格型号:">
                  <el-input v-model="offer_searchData.specifications" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="物料编号:">
                  <el-input v-model="offer_searchData.bomEconding" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="物料名称:">
                  <el-input v-model="offer_searchData.bomName" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="物料规格型号:">
                  <el-input v-model="offer_searchData.bomSpecifications" size="small" placeholder="请输入内容" clearable>
                  </el-input>
                </el-form-item>
                <el-form-item label="子级物料:">
                  <el-checkbox v-model="bomSon"></el-checkbox>
                </el-form-item>
                <el-form-item label="创建起止日期:">
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.startTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                  <el-col class="line" :span="3" :push="1"> - </el-col>
                  <el-col :span="10">
                    <el-date-picker type="date" placeholder="选择日期" v-model="offer_searchData.endTime"
                      style="width: 100%;"></el-date-picker>
                  </el-col>
                </el-form-item>
                <el-form-item>
                  <div class="billingview_searchBtnBox">
                    <el-button type="primary" @click="getListDataByCondition(sel_searchBtn)">搜索</el-button>
                    <el-button @click="show_searchBox=false">取消</el-button>
                  </div>
                </el-form-item>
              </el-form>
              <span slot="reference" class="billingview_searchBut" @click="show_searchBox=!show_searchBox">
                查询<i class="el-icon-search"></i>
              </span>
            </el-popover>
          </li>
          <li @click="showAllVersionData()">
            展开版本
          </li>
          <li>
            <el-tooltip placement="bottom-start">
              <div slot="content">
                <ul>
                  <li>1.若管理员开启bom审核,则在bom审核前可直接删除数据,审核后删除数据则会放入回收站内。</li>
                  <li>2.若管理员开启bom审核,在生产单使用时必须已审核完成才能引用。</li>
                </ul>
              </div>
              <i class="el-icon-info"></i>
            </el-tooltip>
          </li>
        </ul>
      </div>

      <!-- 操作列表 -->
      <div class="head_operateList">
        <ul class="list">
          <li @click="show_AddBomBox">
            <i class="el-icon-plus"></i>新增
          </li>
          <li @click="operateBom(0)">
            <i class="el-icon-plus"></i>提交
          </li>
          <li @click="operateBom(1)" v-if="is_checkBom">
            <i class="el-icon-plus"></i>审批
          </li>
          <li>
            <i class="el-icon-document-copy"></i>复制
          </li>
          <li @click="importBom">
            <i class="el-icon-plus"></i>导入
          </li>
          <li @click="operateBom(3)">
            <i class="el-icon-plus"></i>导出
          </li>
        </ul>
      </div>
    </div>

    <!-- bom表格 -->
    <div class="billingview_tablewrapper billing_table">
      <el-table id="bom_tableBox" ref="bomTableList" row-key="listVersion.id" :tree-props="defaultProps" :indent="18"
        :data="tableData" @select="getSelectedBomData" @select-all="getSelectedAllData" height="100%" border
        style="width: 100%" :default-expand-all="show_allVersion">
        <!-- 索引 -->
        <el-table-column type="index" fixed width="50">
          <template slot="header" slot-scope="scope">
            <el-tooltip placement="bottom-start" effect="light">
              <div slot="content">
                <ul class="billsList_relvanceTips scollStyle">
                  <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                    <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                  </li>
                </ul>
              </div>
              <i class="el-icon-setting"></i>
            </el-tooltip>
          </template>
        </el-table-column>
        <!-- 全选 -->
        <el-table-column type="selection" fixed width="45"></el-table-column>
        <!-- 操作 -->
        <el-table-column label="操作" fixed width="100">
          <template slot-scope="scope">
            <i class="el-icon-edit-outline icon" title="修改" @click="updateBomData(scope.row)"></i>
            <i class="el-icon-warning-outline icon" v-if="[1,3].includes(scope.row.listVersion.bomAuditState)"
              title="驳回" @click="judgeRejectBills(scope.row)"></i>
            <i class="el-icon-delete operate_icon" title="作废" @click="delectBomData(scope.row)"></i>
          </template>
        </el-table-column>
        <!-- 数据内容 -->
        <el-table-column label="状态" sortable width="80" v-if="showListFiledArr.includes(0) && is_checkBom">
          <template slot-scope="scope">
            <el-button v-if="scope.row.listVersion.bomAuditState == 0" type="info" size="mini">未提交</el-button>
            <el-button v-if="scope.row.listVersion.bomAuditState == 1" type="warning" size="mini">审核中</el-button>
            <el-button v-if="scope.row.listVersion.bomAuditState == 2" type="danger" size="mini">已驳回</el-button>
            <el-button v-if="scope.row.listVersion.bomAuditState == 3" type="primary" size="mini">复审中</el-button>
            <el-button v-if="scope.row.listVersion.bomAuditState == 4" type="success" size="mini">已审核</el-button>
          </template>
        </el-table-column>
        <el-table-column prop="product_code" label="货品编码" width="220" v-if="showListFiledArr.includes(1)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="product_old_code" label="旧货品编码" width="220"
          v-if="show_oldProductCode && showListFiledArr.includes(2)" show-overflow-tooltip></el-table-column>
        <el-table-column prop="name" label="货品名称" width="220" v-if="showListFiledArr.includes(3)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="specifications" label="规格型号" width="220" v-if="showListFiledArr.includes(4)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="listVersion.materialAllNum" label="物料数量" width="100" v-if="showListFiledArr.includes(5)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="listVersion.version" label="版本号" width="120" v-if="showListFiledArr.includes(6)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="listVersion.bomDate" label="版本日期" width="120" v-if="showListFiledArr.includes(7)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="listVersion.bomCreateUserName" label="创建人" width="180"
          v-if="showListFiledArr.includes(9)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="listVersion.createTime" label="创建时间" width="180" v-if="showListFiledArr.includes(10)"
          show-overflow-tooltip></el-table-column>
        <el-table-column prop="listVersion.bomAuditUserName" label="审核人" width="180"
          v-if="showListFiledArr.includes(11)" show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="listVersion.bomAuditTime" label="审核时间" width="180" v-if="showListFiledArr.includes(12)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="listVersion.updateTime" label="最后修改时间" width="180" v-if="showListFiledArr.includes(13)"
          show-overflow-tooltip>
        </el-table-column>
        <!-- <el-table-column prop="listVersion.printum" label="打印次数" width="80" v-if="showListFiledArr.includes(14)"
          show-overflow-tooltip>
        </el-table-column>
        <el-table-column prop="listVersion.export_num" label="导出次数" width="80" v-if="showListFiledArr.includes(15)"
          show-overflow-tooltip>
        </el-table-column> -->
      </el-table>
    </div>

    <!-- 表格分页 -->
    <div class="billing_tablePage">
      <el-pagination @size-change="handleSizeChange" @current-change="handleCurrentChange"
        :current-page.sync="pageData.currentPage" :page-sizes="[10, 20, 30, 40]" :page-size.sync="pageData.pageSize"
        layout="total, sizes, prev, pager, next, jumper" :total="pageData.totalPage">
      </el-pagination>
    </div>

    <!-- 驳回条件弹框 -->
    <el-dialog title="驳回原因" :visible.sync="show_reject" width="500px" append-to-body>
      <el-input type="textarea" :rows="4" placeholder="请输入内容" v-model="rejectData">
      </el-input>
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_reject = false">取 消</el-button>
        <el-button size="small" type="primary" @click="rejectBills">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 新增弹框组件 -->
    <bomManageCreate :operationState="operationState" :bomProductData="bomProductData"></bomManageCreate>

    <!-- bom导入组件 -->
    <bomManageExport :showBomExportBox="showBomExportBox" v-if="showBomExportBox"></bomManageExport>

    <!-- 导出列表弹框 -->
    <el-dialog title="导出列表" :visible.sync="isShowExportListBox" width="420px" :before-close="closeExportListBox"
      append-to-body>
      <div>
        <el-row class="exportListBox">
          <el-col :span="4" class="title">
            <span>导出:</span>
          </el-col>
          <el-col :span="13" class="content">
            <el-radio-group v-model="exportListType">
              <el-radio :label="0">导出所选</el-radio>
              <el-radio :label="1">导出全部</el-radio>
            </el-radio-group>
          </el-col>
        </el-row>
        <el-row v-if="exportListType == 1">
          <el-col :span="24">
            <el-col :span="4">起止时间:</el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="startTime" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
            <el-col class="line" :span="3" :push="1"> - </el-col>
            <el-col :span="8">
              <el-date-picker type="date" placeholder="选择日期" v-model="endTime" style="width: 100%;" size="mini">
              </el-date-picker>
            </el-col>
          </el-col>
        </el-row>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="mini" @click="closeExportListBox">取 消</el-button>
        <el-button size="mini" type="primary" @click="commitExportList">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  //导入请求
  import api from "@/network/production/production";
  import exportApi from '@/network/datum/product'; //货品导出相关接口

  // 导入组件
  import bomManageCreate from './bomManage_create.vue'; //新建bom组件
  import bomManageExport from './bomManage_export.vue'; //bom导入组件

  export default {
    name: 'bomManage_view',
    data() {
      return {
        // 列表相关
        defaultProps: { //表格子级匹配字段
          children: 'children',
          hasChildren: 'hasChildren'
        },

        //控制开关
        show_searchBox: false, //是否显示搜索
        show_allVersion: true, //控制是否显示所有版本列表信息
        show_reject: false, //驳回弹框
        show_importBom: false, //导入弹框
        show_createBom: false, //控制是否加载新建弹框
        show_oldProductCode: false, //控制是否显示旧物料编码
        sel_searchBtn: 0, //当前选中查询条件(0:全部 1:已审 2:未审 3:状态 4:高级查询)
        operationState: 0, //操作类型(0:新增 1:修改)
        is_checkBom: true, //是否开启审核(0:否  1:是)

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 100, //总页数
          pageSize: 20 //页面大小
        },

        //表格数据
        tableData: [], //传入表格组件的数据
        bomProductData: {}, //bom货品信息(修改时用)
        bomSelData: [], //当前选择的bom数据
        CurrSelData: {}, //当前操作的数据
        rejectData: '', //驳回原因
        rejectInfo: {}, //驳回货品版本数据
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的

        // 高级搜索框数据
        offer_searchData: { //高级搜索数据
          econding: '', //编号
          name: '', //名称
          specifications:'',//规格名称
          bomEconding: '', //编号
          bomName: '', //名称
          bomSpecifications:'',//规格名称
          startTime: '', //创建开始时间
          endTime: '', //创建结束时间
        },
        bomSon:false,//是否需要查询子级物料

        //bom导入相关
        showBomExportBox: false, //控制显示bom导入弹框

        //bom导出相关
        isShowExportListBox:false,//货品导出控制弹框
        exportListType: 0, //导出方式(0:已选  1:全部)
        startTime: '', //开始时间
        endTime: '', //结束时间
        proPropertyId:'',//属性id
        proSourceId:'',//来源id
        exportBomState:'',//导出是否有审核条件
        exportBomProcess:'',//导出是否有设置已设工序条件

        //辅助数据
        tips: '', //提示信息
      }
    },
    created() {

    },
    mounted() {
      // 初始化界面所需参数(vuex数据)
      this.initVuextData();
      // 判断界面显示数据
      this.judgeShowData();
      //初始化列表显示字段数据
      this.initListShowFiledData();
      //获取界面数据
      this.getListDataByCondition(0);
    },
    computed: {
      ...mapState({
        ProductUnit: state => state.system.ProductUnit, //计量单位
        show_billsCreateBox: state => state.billing.show_billsCreateBox, //控制显示新建弹框组件
        userPowerInfo: state => state.user.userPowerInfo, //用户权限
        companyparamlist: state => state.companyparamlist, //获取企业级参数
        bomListFileData: state => state.bill_list_config.bomListFileData, //bom版本列表可显示字段
        bomExportKeys: state => state.system.bomExportKeys, //bom导出相关字段
        UserInfo: state => state.user.UserInfo, //当前登录用户信息
      }),
    },
    watch: {
      // 监听新建界面打开或关闭
      show_billsCreateBox(newVal) {
        if (!newVal) {
          // 关闭加载新建弹框
          this.show_createBom = false;
          //默认获取一次界面数据
          this.getListDataByCondition(this.sel_searchBtn);
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
    },
    methods: {
      ...mapMutations([
        'SHOW_BILLSCREATEBOX', //控制新建弹框是否显示
      ]),

      ...mapActions([
        'getUserPowerInfo', //获取用户操作权限
      ]),

      /* 初始化界面所需数据vuex数据 */
      initVuextData() {
        //判断用户权限
        if (this.userPowerInfo.length == 0) {
          this.getUserPowerInfo();
        }
      },

      /* 判断界面需要显示的权限数据 */
      judgeShowData() {
        //判断是否显示旧物料编码
        if (this.companyparamlist.param345 == "0") { //启用
          this.show_oldProductCode = true;
        }
        //判断是否开启审核
        this.is_checkBom = this.companyparamlist.param264 == "0" ? true : false;
      },

      /* 显示所有版本列表信息 */
      showAllVersionData() {
        this.show_allVersion = !this.show_allVersion;
      },

      /* 根据条件查询列表信息type(0:全部 1:已审 2:未审  3:状态  4:高级查询)*/
      getListDataByCondition(type) {
        //判断是否有查询权限
        if (this.commonJsExtend.isHaveThePower(16, 3, 1, '查询')) {
          this.tableData = []
          return
        }
        //设置查询按钮选中
        this.sel_searchBtn = type;
        //定义传入后端的参数
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.pageSize, //页面大小
        }
        if (type == 0) { //全部
        this.exportBomState=''
        this.exportBomProcess=''
        } else if (type == 1) { //已审
          data.bom_state = 4
          this.exportBomState=data.bom_state
        } else if (type == 2) { //未审
          data.bom_state = 1
          this.exportBomState=data.bom_state
          this.exportBomProcess=''
        } else if (type == 3) { //已设工序
          data.is_bom_process = 1
          this.exportBomProcess=data.is_bom_process
          this.exportBomState=''
        } else if (type == 4) { //搜索
        }

        //定义高级查询条件
        let {
          econding, //编号
          name, //名称
          specifications,//规格型号
          bomEconding, //物料编号
          bomName, //物料名称
          bomSpecifications,//物料规格名称
          startTime, //创建开始时间
          endTime //创建结束时间
        } = this.offer_searchData

        let today = new Date()
        today.setHours(0, 0, 0, 0)
        if ((startTime != '' && startTime != null) || (endTime != '' && endTime != null)) { //起止时间其中一个不为空
          if (startTime == '' || endTime == '') { //起止时间其中一个为空
            this.$message({
              type: 'warning',
              message: '查询条件为起止时间时必须两个都填写!',
              duration: this.elDuration
            })
            return
          } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
            this.$message({
              type: 'warning',
              message: '结束日期不能早于开始日期!',
              duration: this.elDuration
            })
            return
          } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
            .getTime()) { //所选择的日期不能早于当前日期
            this.$message({
              type: 'warning',
              message: '所选择的日期不能早于当前日期!',
              duration: this.elDuration
            })
            return
          } else { //限制日期选择区间
            let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
            if (duringTime > 90) {
              this.$message({
                type: 'warning',
                message: '日期区间不能超过90天!',
                duration: this.elDuration
              })
              return
            }
          }
        }
        this.show_searchBox = false;
        //查询条件为
        // 编号
        if (econding != '') {
          data.product_code = econding
        }
        //名称
        if (name != '') {
          data.name = name
        }
        //规格型号
        if (specifications != '') {
          data.specifications = specifications
        }
        // 物料编号
        if (bomEconding != '') {
          data.bomEconding = bomEconding
        }
        //物料名称
        if (bomName != '') {
          data.bomName = bomName
        }
        //物料规格型号
        if (bomSpecifications != '') {
          data.bomSpecifications = bomSpecifications
        }
        //是否需要子级物料
        if(this.bomSon){
          data.bomSon=1
        }
        //创建开始时间
        if (startTime != '') {
          data.startTime = this.commonJsExtend.getDateTime(startTime,0)
        }
        //创建结束时间
        if (endTime != '') {
          // data.endTime = endTime
          data.endTime = this.commonJsExtend.getDateTime(endTime,0)
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#bomManage_tableBox", 4, 'bom列表获取中,请稍候...');
        // 发送请求
        api.findProductHasBomSet(data).then(res => {
          //关闭弹框
          this.loading.close();
          //验证请求后的结果
          if (res.code == 200) {
            this.tableData = [];
            //处理列表数据
            let listData = res.data.rows;
            listData.forEach((itemI, indexI) => {
              if (itemI.productBomVersionList.length > 0) {
                //定义列表数据值
                let listItem = JSON.parse(JSON.stringify(itemI));
                //获取默认版本
                let defaultVersion = [];
                defaultVersion = itemI.productBomVersionList.filter(item => item.isDefault == 1);
                if (defaultVersion.length > 0) {
                  listItem.listVersion = defaultVersion[0];
                  listItem.children = [];
                }
                //获取其他版本
                let otherVersion = [];
                otherVersion = itemI.productBomVersionList.filter(item => item.isDefault == 0);
                //处理成子级
                if (otherVersion.length > 0) {
                  otherVersion.forEach((itemJ, indexJ) => {
                    let childrenList = JSON.parse(JSON.stringify(itemI));
                    childrenList.listVersion = itemJ;
                    //判断是否有子级
                    if (!!listItem.children) {
                      listItem.children.push(childrenList)
                    } else {
                      listItem.listVersion = childrenList;
                      listItem.children = [];
                    }
                  })
                }
                this.tableData.push(listItem);
              }
            })
            //获取分页数据
            this.pageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 操作bom数据(type:(0:提交  1:审批  2:驳回  3:导出)) */
      operateBom(type) {
        // 判断是否可以处理
        if (this.bomSelData.length == 0 && type!=3) { //判断为空
          this.$message({
            type: 'warning',
            message: '请选择需要处理的数据!',
            duration: this.elDuration
          })
          return
        } else if (this.bomSelData.length > 1 && type!=3) { //大于1的情况
          this.$message({
            type: 'warning',
            message: '一次只能处理一条数据!',
            duration: this.elDuration
          })
          return
        }
        //将当前选择数据存入data
        this.CurrSelData = this.bomSelData[0];
        //判断处理类型
        if (type == 0) { //提交
          //定义可提交的状态
          let deleteState = [0, 2] //0:未提交  2:已驳回
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.CurrSelData.listVersion.bomAuditState)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可提交',
              duration: this.elDuration
            })
            return
          }
          //定义传入后端的值
          let submitData = {
            isSubmit: 1, //bom状态
            versionId: this.CurrSelData.listVersion.id, //版本id
            productName: this.CurrSelData.name, //货品名称
          }
          // 请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#bomManage_tableBox", 4, '提交审核中,请稍后...');
          // 发送提交审核请求
          api.updateBomSubmit(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.getListDataByCondition();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        } else if (type == 1) { //审批
          // 定义可审核状态
          let deleteState = [1, 3] //1:审核中  3:复审中
          // 判断该数据是否可以提交
          if (!deleteState.includes(this.CurrSelData.listVersion.bomAuditState)) {
            this.$message({
              type: 'warning',
              message: '当前状态不可审批',
              duration: this.elDuration
            })
            return
          }
          let submitData = {
            isSubmit: 4, //bom状态
            versionId: this.CurrSelData.listVersion.id, //版本id
            productName: this.CurrSelData.name, //货品名称
          }
          // 请求前加载loading框
          this.loading = this.commonJsExtend.customLoading("#bomManage_tableBox", 4, 'bom审核中,请稍后...');
          //发送提交审核请求
          api.updateBomStatusYes(submitData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.getListDataByCondition();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }else if(type==3){
          //判断是否有导出权限
          if (this.commonJsExtend.isHaveThePower(21, 2, 5, '导出')) {
            return
          }
          //获取默认开始日期和结束日期
          let today = new Date();
          //设置时间为零点
          today.setHours(0, 0, 0, 0);
          //结束时间默认当前日期
          this.endTime = today;
          //开始时间默认当前日期往前推90天
          this.startTime = new Date(today.getTime() - 24 * 60 * 60 * 1000 * 90);
          this.isShowExportListBox=true
        }
      },

      /* 获取选中的bom数据 */
      getSelectedBomData(selection, row) {
        //获取单选数据
        this.bomSelData = [];
        this.bomSelData=selection
        // this.bomSelData.push(row);
        // //设置单选
        // this.$refs.bomTableList.clearSelection();
        // this.$refs.bomTableList.toggleRowSelection(row);
      },

      /* 全选列表数据 */
      getSelectedAllData() {
        //直接清除,不可全选
        this.$refs.bomTableList.clearSelection();
      },

      /* 页面大小发生改变 */
      handleSizeChange(val) {
        //获取分页大小
        this.pageData.pageSize = val;
        //请求数据
        this.getListDataByCondition(this.sel_searchBtn)
      },

      /* 当前页发生改变 */
      handleCurrentChange(val) {
        //获取当前页
        this.pageData.currentPage = val;
        //请求数据
        this.getListDataByCondition(this.sel_searchBtn)
      },

      /* 显示新增BOM弹框 */
      show_AddBomBox() {
        //判断是否有新增权限
        if (this.commonJsExtend.isHaveThePower(16, 3, 2, '新增')) {
          return
        }
        //设置状态为新增
        this.operationState = 0;
        // 加载新建弹框
        this.show_createBom = true;
        //显示弹框
        this.SHOW_BILLSCREATEBOX(true)
      },

      //关闭导出弹框
      closeExportListBox(){
        this.isShowExportListBox=false
      },
      //bom导出请求
      commitExportList(){
        if(this.companyparamlist.param373==0){
          let jurisdiction=this.companyparamlist.param375.split(",")
          if(!jurisdiction.includes(this.UserInfo.user_id.toString())){
            this.$message({
              type: 'warning',
              message: '你没有导出权限!',
              duration: this.elDuration,
            })
            return
          }
        }
        let exportIdsArr = [];
          if(this.exportListType==0){
            //定义导出的列表数据id字段
              //判断是否已选数据
              if (this.bomSelData.length == 0) {
                this.$message({
                  type: 'warning',
                  message: '请先选择需要导出的数据!',
                  duration: this.elDuration,
                })
                return
              }
            this.startTime=''
            this.endTime=''
            this.bomSelData.forEach((item,index)=>{
              exportIdsArr.push(item.listVersion.id);
            })
            this.closeExportListBox()
          }else if (this.exportListType == 1) { //导出全部

          //判断日期差
          if (!this.getDifferTime(this.startTime, this.endTime)) {
            return
          }
          this.startTime=this.commonJsExtend.getDateTime(this.startTime,0)
          this.endTime=this.commonJsExtend.getDateTime(this.endTime,0)
        }
        //定义后端公共接受参数
        let exportData = {
          ids: exportIdsArr.join(","),
          startTime: this.startTime,
          endTime: this.endTime,
        }
        if(!!this.exportBomState &&this.exportListType == 1){
          exportData.exportBomState=this.exportBomState
        }
        if(!!this.exportBomProcess&&this.exportListType == 1){
          exportData.exportBomProcess=this.exportBomProcess
        }
        if(!!this.offer_searchData.econding&&this.exportListType == 1){
          exportData.econding=this.offer_searchData.econding
        }
        if(!!this.offer_searchData.name&&this.exportListType == 1){
          exportData.name=this.offer_searchData.name
        }
        if(!!this.offer_searchData.bomName&&this.exportListType == 1){
          exportData.bomName=this.offer_searchData.bomName
        }
        if(!!this.offer_searchData.bomEconding&&this.exportListType == 1){
          exportData.bomEconding=this.offer_searchData.bomEconding
        }
        if(!!this.offer_searchData.bomSpecifications&&this.exportListType == 1){
          exportData.bomSpecifications=this.offer_searchData.bomSpecifications
        }
        if(!!this.offer_searchData.startTime &&!!this.offer_searchData.endTime&&this.exportListType == 1){
          exportData.startTime=this.commonJsExtend.getDateTime(this.offer_searchData.startTime,0)
          exportData.endTime=this.commonJsExtend.getDateTime(this.offer_searchData.endTime,0)
        }


        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, '单据列表导出中,请稍候...');
        exportData.fields = this.bomExportKeys.join(','); //需要导出的字段名
        exportApi.exportBomList(exportData).then(res=>{
            if (res.code == 200 && res.data) {
            let filePathArr = [];
            let name = '';
            filePathArr = res.data.split('//')
            name = decodeURI(filePathArr[filePathArr.length - 1])
            //设置操作类型为导出excle
            this.operate = 1;
            //下载文件
            this.downloadFile(res.data, name, 1)
            //关闭导出弹框
            this.closeExportListBox()
            this.loading.close();
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
            this.loading.close();
          }
        })
      },
      // 获取时间差startTime:开始时间  endTime:结束时间
      getDifferTime(startTime, endTime) {
        if (startTime == "" && endTime == "") {
          return true;
        }
        //判断日期区间
        let today = new Date(); //今天日期
        today.setHours(0, 0, 0, 0);
        if (startTime == '' || endTime == '') { //起止时间其中一个为空
          this.$message({
            type: 'warning',
            message: '起止日期不能为空!',
            duration: this.elDuration
          })
          return false;
        } else if (endTime.getTime() - startTime.getTime() < 0) { //结束日期不能早于开始日期
          this.$message({
            type: 'warning',
            message: '结束日期不能早于开始日期!',
            duration: this.elDuration
          })
          return false;
        } else if (today.getTime() < startTime.getTime() || today.getTime() < endTime
          .getTime()) { //所选择的日期不能早于当前日期
          this.$message({
            type: 'warning',
            message: '所选择的日期不能早于当前日期!',
            duration: this.elDuration
          })
          return false;
        } else { //限制日期选择区间
          let duringTime = (endTime.getTime() - startTime.getTime()) / (24 * 60 * 60 * 1000)
          if (duringTime > 90) {
            this.$message({
              type: 'warning',
              message: '日期区间不能超过90天!',
              duration: this.elDuration
            })
            return false;
          }
        }
        return true;
      },
/* 下载文件或打开pdf文件 */
      downloadFile(href, name) {
        if (this.operate == 2) { //为pdf文件时
          let filePreViewData = {
            file_path: href,
            name
          }
          this.showFilePreView(filePreViewData)
        } else if (this.operate == 1) { //为其他文件时
          const link = document.createElement("a");
          link.style.display = 'none';
          link.href = baseUrl + href;
          link.download = name;
          document.body.appendChild(link);
          link.click();
        }
      },
      /* 修改BOM物料 */
      updateBomData(data) {
        //判断是否有修改权限
        if (this.commonJsExtend.isHaveThePower(16, 3, 4, '修改')) {
          return
        }
        //设置操作状态为修改
        this.operationState = 1
        //获取修改信息的货品id
        this.bomProductData = data;
        // 加载新建弹框
        this.show_createBom = true;
        //显示弹框
        this.SHOW_BILLSCREATEBOX(true)
      },

      /* 作废BOM */
      delectBomData(data) {
        //判断是否有作废权限
        if (this.commonJsExtend.isHaveThePower(16, 3, 3, '作废')) {
          return
        }
        this.$confirm('确定要作废当前bom版本信息吗?', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning',
        }).then(() => {
          var deleteData = {
            versionId: data.listVersion.id, //货品id
          }
          console.log(deleteData);
          //发送后端请求
          api.updateBomDeleteFlag(deleteData).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: '删除成功',
                duration: this.elDuration
              })
              this.getListDataByCondition(0)
            } else {
              this.$message({
                type: 'error',
                message: res.message
              })
            }
          })
        }).catch(() => {})
      },

      /* 显示驳回弹框 */
      judgeRejectBills(data) {
        //获取驳回的数据
        this.rejectInfo = data;
        // 判断是否可以驳回
        let rejectState = [1];
        if (!rejectState.includes(this.rejectInfo.listVersion.bomAuditState)) {
          this.$message({
            type: 'warning',
            message: "该状态下无法驳回",
            duration: this.elDuration
          })
          return
        }
        //显示驳回弹框
        this.show_reject = true;
      },

      /* 处理驳回业务 */
      rejectBills() {
        var data = {
          isSubmit: 2, //驳回后的状态
          versionId: this.rejectInfo.listVersion.id, //版本id,
          reason: this.rejectData, //驳回的原因
          productName: this.rejectInfo.name, //货品名称
        }
        api.updateBomStatusNo(data).then(res => {
          if (res.code == 200) {
            this.$message({
              type: 'success',
              message: '驳回成功'
            })
            this.getListDataByCondition(0)
          } else {
            this.$message({
              type: 'error',
              message: res.message
            })
          }
        })
        this.show_reject = false;
        this.rejectData = '';
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('bomListArr', this.bomListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('bomListArr', this.bomListFileData)
          .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('bomListArr', this.showListFiledData);
        //判断其它控制权限
        this.showListFiledData.forEach((item, index) => {
          //判断是否开启旧物料编码
          if (item.value == 2) {
            if (this.companyparamlist.param345 == "1") { //不启用
              this.showListFiledData.splice(index, 1);
            }
          }
        })
      },

      /* 关闭bom导入弹框 */
      closeImportBomBox() {
        this.showBomExportBox = false;
      },

      /* 显示bom导入弹框 */
      importBom() {
        //判断是否有导入权限
        if (this.commonJsExtend.isHaveThePower(16, 3, 6, '导入')) {
          return
        }
        this.showBomExportBox = true;
      },
    },
    components: {
      bomManageCreate,
      bomManageExport
    }
  }
</script>
