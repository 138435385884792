<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：货品bom版本选择组件
	开始时间：2022-09-28
	开发人员：刘巍骏
	最后修改：2022-09-28
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="List">
    <el-dialog title="货品Bom版本" :visible.sync="show_BomVersionBox" :before-close="colse_createBox" width="85%" top="8vh"
      append-to-body>
      <!-- 列表总框 -->
      <div class="billing_dialog_table billing_dialog_product" id="bomVersionBox">
        <!-- 左边分类 -->
        <div class="list_left">
          <!-- 分类标题 -->
          <div class="classfi_title" :class="{'classfi_title_sel':conditionType==0}" @click="getProductListData(0)">
            <i class="el-icon-menu"></i>
            <span>全部分类</span>
          </div>
          <!-- 分类列表 -->
          <div>
            <el-tree :data="ProductClassfiy" :props="defaultProps" node-key="id" ref="tree" default-expand-all
              :expand-on-click-node="false" @node-click="getCurrClassify">
              <span class="custom-tree-node" slot-scope="{ node, ProductClassfiy}">
                <span>{{ node.label }}</span>
              </span>
            </el-tree>
          </div>
        </div>

        <!-- 可拉伸框 -->
        <div class="list_handel"></div>

        <!-- 右边数据 -->
        <div class="list_right">
          <!-- 头部搜索框 -->
          <div class="right_top">
            <!-- 搜索 -->
            <div class="search">
              <input type="text" v-model="currSearchVal" placeholder="请输入编号/名称/规格">
              <i class="el-icon-search" @click="getListDataByCondition()"></i>
            </div>
            <!-- 刷新 -->
            <div class="operateBtn">
              <el-button type="primary" @click="getListDataByCondition()" size="small">刷新</el-button>
            </div>
          </div>
          <!-- 列表 -->
          <div class="right_list">
            <!-- 表格 -->
            <div class="right_list_table billing_table">
              <el-table :data="tableData" height="100%" border style="width: 100%" @select="getselectedProduct"
                @select-all="getSelectedCurrentProduct" ref="productSelected" :row-key="(row)=> row.listVersion.id">
                <!-- 索引 -->
                <el-table-column type="index" fixed width="40"></el-table-column>
                <!-- 全选 -->
                <el-table-column type="selection" fixed width="45"></el-table-column>
                <!-- 数据内容 -->
                <el-table-column prop="product_code" label="货品编码" width="220" show-overflow-tooltip></el-table-column>
                <el-table-column prop="name" label="货品名称" width="220" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="specifications" label="规格型号" width="220" show-overflow-tooltip></el-table-column>
                <el-table-column prop="listVersion.materialAllNum" label="物料数量" width="100" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="listVersion.version" label="版本号" width="120" show-overflow-tooltip>
                </el-table-column>
                <el-table-column prop="listVersion.bomDate" label="版本日期" width="120" show-overflow-tooltip>
                </el-table-column>
              </el-table>
            </div>
            <!-- 分页 -->
            <div class="right_list_page">
              <el-pagination small @size-change="handleSizeChange" @current-change="handleCurrentChange"
                :current-page.sync="pageData.currentPage" :page-sizes="[20,50,100]"
                :page-size.sync="pageData.currentPageSize" layout="total, sizes, prev, pager, next, jumper"
                :total="pageData.totalPage">
              </el-pagination>
            </div>
          </div>
        </div>
      </div>
      <!-- 确认取消 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" size="mini" @click="commit_product">确认</el-button>
        <el-button size="mini" @click="colse_createBox">取 消</el-button>
      </span>
    </el-dialog>

    <!-- 货品详情组件 -->
    <productDetail :productId="detailProductId" v-if="show_productDetailBox"></productDetail>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'

  // 导入接口
  import api from '@/network/system/dictionary'; //数据字典接口
  import productApi from "@/network/production/production";
  import req from '@/network/datum/product'; //货品接口

  // 导入组件
  import productDetail from '@/components/commComponent/dialog/product_detail.vue'; //获取详情组件

  export default {
    name: 'list_bom_version',
    props: {
      //是否显示版本弹框
      show_BomVersionBox: {
        type: Boolean,
        default: false
      },
      //默认选中下级
      productBaseData: {
        type: Object,
        default () {
          return {}
        }
      },
    },
    data() {
      return {
        //当前组件数据
        tableData: [], //货品列表数据
        conditionType: 0, //货品分类默认选中
        currSearchVal: '', //搜索条件

        //el-tree配置
        defaultProps: {
          children: 'children',
          label: 'name'
        },

        //分页数据
        pageData: {
          currentPage: 1, //当前页
          totalPage: 0, //总页数
          currentPageSize: 20 //页面大小
        },

        //货品详情子组件相关
        detailProductId: '', //查看详情的货品id
        show_productDetailBox: '', //控制显示货品详情弹框
        selectBom:'',//已经选中的bom数据
        selectQuery:'',//当请求发生改变的时候保留原来选择的数据
        limitNumber:1,//第一次点击指定下级菜单才进行回显
      }
    },
    computed: {
      ...mapState({
        ProductClassfiy: state => state.system.ProductClassfiy, //货品分类数据
        companyparamlist: state => state.companyparamlist, //获取企业级参数
      }),
    },
    mounted() {
      //初始化数据字典数据
      this.initDictoryData();
    },
    watch:{
      // 监听显示列表数据发生变化
      tableData: {
        handler(newVal) {
         this.getDefaultAssginSelData()
        },
        deep: true
      },
      },

    methods: {
      ...mapActions([
        'getProductClassfiy', //获取货品分类数据
      ]),

      /* 初始化数据字典数据 */
      initDictoryData() {
        // 判断vuex是否有货品分类数据
        if (this.ProductClassfiy.length == 0) {
          this.getProductClassfiy();
        }

        //获取货品bom列表数据
        this.getListDataByCondition();

      },

      /* 获取默认选中的下级bom数据 */
      getDefaultAssginSelData() {
        let selverIdArr=[]
        let originalData=[]
        let productAssginIds=[]
        let productAssginNames=[]
        if(!!this.productBaseData.assignNextVersionId){
          productAssginIds=this.productBaseData.assignNextVersionId.split(",")
           productAssginNames=this.productBaseData.assignNextVersionName.split(",")
        }
        if(productAssginIds.length>0){
          //默认选中已选下级bom
          this.$nextTick(()=>{
           this.tableData.forEach((item,index)=>{
              if(productAssginIds.includes(item.listVersion.id.toString())){
                this.$refs.productSelected.toggleRowSelection(item,true)
              }
           })
           if(this.limitNumber===1){
             productAssginIds.forEach((item,index)=>{
               let names =productAssginNames[index].split("(")
               let name=names[0]
               let procodes=names[1].split(")")[0]
               let obj={
                 name:name,
                 product_code:procodes,
                 listVersion:{
                   id:Number(item)
                 }
               }
               originalData.push(obj)
             })
             if(!!originalData&&originalData.length>0){
               this.selectBom=originalData
               this.selectQuery=originalData
             }
             this.limitNumber=2
           }
          })
        }
        //当本身进行选择后进行查询更换数据的时候回显一开始所选的数据
        if(this.selectQuery.length>0){
          this.$nextTick(()=>{
            let selectQueryVersionId=[]
            this.selectQuery.forEach((item,index)=>{
              selectQueryVersionId.push(item.listVersion.id)
            })
            this.tableData.forEach((item,index)=>{
               if(selectQueryVersionId.includes(item.listVersion.id)){
                 this.$refs.productSelected.toggleRowSelection(item,true)
               }
            })
          })
        }

      },

      /* 获取货品bom列表数据 */
      getListDataByCondition() {
        //判断是否有查询权限
        if (this.commonJsExtend.isHaveThePower(16, 3, 1, '查询')) {
          this.tableData = []
          return
        }

        //定义请求数据
        let data = {
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.currentPageSize, //页面大小
        }

        //判断货品分类
        if(this.conditionType != 0){

        }

        //判断是否有输入框查询条件
        if(this.currSearchVal.trim()!=""){
          data.searchName=this.currSearchVal.trim()
        }
        //防止连续发送请求产生的问题
        if(this.selectQuery.length>0){
          let selectQueryIds=[]
          this.selectQuery.forEach((item,index)=>{
            selectQueryIds.push(item.listVersion.id)
          })
          this.selectBom.forEach((item,index)=>{
            if(!selectQueryIds.includes(item.listVersion.id)){
              this.selectQuery.push(item)
            }
          })
        }
        else{
          //每次发送请求的时候将一开始所选的保存下来
          this.selectQuery=this.selectBom
        }
        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#bomVersionBox", 4, 'bom列表获取中,请稍候...');
        // 发送请求
        productApi.findProductHasBomSet(data).then(res => {
          //验证请求后的结果
          if (res.code == 200) {

            this.tableData = [];
            //处理列表数据
            let listData = res.data.rows;
            listData.forEach((itemI, indexI) => {
              if (itemI.productBomVersionList.length > 0) {
                //定义列表数据值
                let listItem = JSON.parse(JSON.stringify(itemI));
                //获取默认版本
                let defaultVersion = [];
                defaultVersion = itemI.productBomVersionList.filter(item => item.isDefault == 1);
                if (defaultVersion.length > 0) {
                  listItem.listVersion = defaultVersion[0];
                  listItem.children = [];
                }
                //获取其他版本
                let otherVersion = [];
                otherVersion = itemI.productBomVersionList.filter(item => item.isDefault == 0);
                //处理成子级
                if (otherVersion.length > 0) {
                  otherVersion.forEach((itemJ, indexJ) => {
                    let childrenList = JSON.parse(JSON.stringify(itemI));
                    childrenList.listVersion = itemJ;
                    //判断是否有子级
                    if (!!listItem.children) {
                      listItem.children.push(childrenList)
                    } else {
                      listItem.listVersion = childrenList;
                      listItem.children = [];
                    }
                  })
                }
                this.tableData.push(listItem);
              }
            })
            //获取分页数据
            this.pageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }

          //关闭弹框
          this.loading.close();
        })
      },

      /* 获取当前选中货品分类 */
      getCurrClassify(data) {
        //将所选货品分类数据存入data
        this.currClassifyData = data;
        //查询货品列表数据
        this.getProductListData(1);
      },

      /* 获取货品列表信息type((0:全部  1:分类)) */
      getProductListData(type) {
        //将查询类型存入data
        this.conditionType = type;
        //定义传入后端的参数
        let data = { //公共条件
          pageIndex: this.pageData.currentPage, //当前页
          pageSize: this.pageData.currentPageSize, //页面大小
          condition: this.conditionType, //查询条件
          isDown: 0, //是否下架(0:否  1:是)
          value: this.currSearchVal, //搜索框值
        }
        // 分类查询
        if (this.conditionType == 1) { //分类查询
          data.classfiyId = this.currClassifyData.id;
        }
        //防止连续发送请求产生的问题
        if(this.selectQuery.length>0){
          let selectQueryIds=[]
          this.selectQuery.forEach((item,index)=>{
            selectQueryIds.push(item.listVersion.id)
          })
          this.selectBom.forEach((item,index)=>{
            if(!selectQueryIds.includes(item.listVersion.id)){
              this.selectQuery.push(item)
            }
          })
        }
        else{
          //每次发送请求的时候将一开始所选的保存下来
          this.selectQuery=this.selectBom
        }

        // 发送请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#bomVersionBox", 4, 'bom列表获取中,请稍候...');
        // 发送请求
        productApi.findProductHasBomSet(data).then(res => {
          //验证请求后的结果
          if (res.code == 200) {
            this.tableData = [];
            //处理列表数据
            let listData = res.data.rows;
            listData.forEach((itemI, indexI) => {
              if (itemI.productBomVersionList.length > 0) {
                //定义列表数据值
                let listItem = JSON.parse(JSON.stringify(itemI));
                //获取默认版本
                let defaultVersion = [];
                defaultVersion = itemI.productBomVersionList.filter(item => item.isDefault == 1);
                if (defaultVersion.length > 0) {
                  listItem.listVersion = defaultVersion[0];
                  listItem.children = [];
                }
                //获取其他版本
                let otherVersion = [];
                otherVersion = itemI.productBomVersionList.filter(item => item.isDefault == 0);
                //处理成子级
                if (otherVersion.length > 0) {
                  otherVersion.forEach((itemJ, indexJ) => {
                    let childrenList = JSON.parse(JSON.stringify(itemI));
                    childrenList.listVersion = itemJ;
                    //判断是否有子级
                    if (!!listItem.children) {
                      listItem.children.push(childrenList)
                    } else {
                      listItem.listVersion = childrenList;
                      listItem.children = [];
                    }
                  })
                }
                this.tableData.push(listItem);
              }
            })
            //获取分页数据
            this.pageData.totalPage = res.data.total;
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }

          //关闭弹框
          this.loading.close();
        })

      },


      /* 页面数据总量发生变化触发 */
      handleSizeChange(val) {
        //将数据存入列表
        this.currentPageSize = val;
        //获取货品列表数据
        this.getProductListData();
      },

      /* 分页页码发生变化触发 */
      handleCurrentChange(val) {
        //将数据存入列表
        this.currentPage = val;
        //获取货品列表数据
        this.getProductListData();
      },

      /* 获取选中的货品数据 */
      getselectedProduct(row) {
        // console.log(this.selectBom)
        //如果这个值大于0就等于先前有选好的值
        if(this.selectQuery.length>0){
          //保留当前页面数据没有之前选择的数据，有的删除让客户自己选择该当前页面的相关数据
          this.tableData.forEach((item,idex)=>{
             this.selectQuery.forEach((itemI,indexI)=>{
               if(item.listVersion.id==itemI.listVersion.id){
                 this.selectQuery=this.deleteArrObj(this.selectQuery,itemI)
               }
             })
          })
        }
        this.selectBom=row

        if(!!this.selectQuery&&this.selectQuery.length>0){
          this.selectQuery.forEach((item,index)=>{
            this.selectBom.push(item)
          })
        }

      },

      /* 删除数组中的某个元素 */
      deleteArrObj(data, obj) {
        let result = JSON.parse(JSON.stringify(data));
        result.forEach((item, index) => {
          if (JSON.stringify(item).indexOf(JSON.stringify(obj)) != -1) {
            result.splice(index, 1);
          }
        })
        return result;
      },

      /* 全选货品列表数据 */
      getSelectedCurrentProduct() {
           this.$message({
             type: 'warning',
             message: "暂时不可以全选",
             duration: this.elDuration
           })
           this.$refs.productSelected.clearSelection();
      },

      /*提交当前组件提交的数据*/
      commit_product() {
        // console.log(this.selectBom)
        this.$emit('SelectedData',this.selectBom)
        this.colse_createBox()
      },

      /*关闭当前组件*/
      colse_createBox() {
        this.$parent.closeBomListBox()
      }
    },
  }
</script>

<style>
</style>
