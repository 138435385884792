<!--
	程序版本：V3.0
	版权所有：深圳市科飞时速网络科技有限公司
	技术支持：Tech@21gmail.com
	单元名称：Bom新建页面
	开始时间：2021-01-14
	开发人员：刘巍骏
	最后修改：2021-01-14
	备注说明：如需修改请联系开发人员
-->
<template>
  <div class="bomCreateBox">
    <el-dialog :title="(operationState == 0 ? '新增' : '修改') + 'BOM'" :visible.sync="show_billsCreateBox"
      :before-close="colse_createBox" :fullscreen="true" id="addBomBox">
      <div class="billingcreate">
        <!-- 单据表单 -->
        <div class="billingcreate_form">
          <ul class="form_ul">
            <li>
              <div class="form_title">
                <span class="mustHint">*</span>
                货品名称:
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.productName" placeholder="请选择货品">
                <span>
                  <i class="el-icon-more" @click="show_getProductbox"></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                货品编码:
              </div>
              <div class="form_date">
                <input disabled type="text" disabled="disabled" v-model="productBaseData.productCode"
                  placeholder="请选择货品">
              </div>
            </li>
            <li v-if="show_oldMaterialCode">
              <div class="form_title">
                货品旧编码:
              </div>
              <div class="form_date">
                <input disabled type="text" disabled="disabled" v-model="productBaseData.productOldCode"
                  placeholder="请选择货品">
              </div>
            </li>
            <li>
              <div class="form_title">
                货品规格:
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.productSpecifal" placeholder="请选择货品">
              </div>
            </li>
            <li>
              <div class="form_title">
                bom属性:
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.productProperty" placeholder="请选择属性">
                <span @click="show_bomProperty=!show_bomProperty">
                  <i class="el-icon-arrow-down"></i>
                </span>
                <!-- 下拉菜单 -->
                <ul class="pulldown_menu" v-show="show_bomProperty">
                  <li v-for="elem in bomPropertyData" @click="getBomProperty(elem)">{{elem.name}}</li>
                </ul>
              </div>
            </li>
            <li>
              <div class="form_title">
                <span class="mustHint">*</span>
                版本号:
              </div>
              <div class="form_date">
                <input type="text" v-model="productBaseData.productVersion"
                  @change="updateBomVersionInfo('productVersion')" placeholder="请填写版本">
              </div>
            </li>
            <li>
              <div class="form_title">
                版本日期:
              </div>
              <div class="form_date">
                <el-date-picker type="date" v-model="productBaseData.productDate"
                  @change="updateBomVersionInfo('productDate')" placeholder="请选择日期" :prefix-icon="''" />
                <span>
                  <i class="el-icon-date" ></i>
                </span>
              </div>
            </li>
            <li>
              <div class="form_title">
                引用模板:
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.productModel" placeholder="请选择模板货品">
              </div>
            </li>
            <li>
              <div class="form_title">
                设置客户:
              </div>
              <div class="form_date" @click="show_customerBomSet=true">
                <input disabled type="text" v-model="productBaseData.productCustomerSet" placeholder="请设置客户">
              </div>
            </li>
            <li>
              <div class="form_title">
                总成本(<span class="calculate_span" @click="CalculateTotalCost()">计算</span>):
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.totalCostPrice" placeholder="请计算总成本">
              </div>
            </li>
            <li>
              <div class="form_title" >
                总材料费用(<span class="calculate_span" @click="CalculateTotalMaterialCost()">计算</span>):
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.totalMaterialCost" placeholder="请计算总材料费用">
              </div>
            </li>
            <li>
              <div class="form_title">
                毛生产费用(<span class="calculate_span" @click="grossExpenses()">计算</span>):
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.totalGrossCost" placeholder="请计算毛生产费用">
              </div>
            </li>
            <li>
              <div class="form_title">
                含归属子级成本:
              </div>
              <div class="form_data_filed">
                <el-checkbox v-model="productBaseData.isChildCost" @change="attributedCost()"></el-checkbox>
              </div>
            </li>
            <li>
              <div class="form_title">
                独有层级:
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.levelAlone" placeholder="请设置独有层级">
              </div>
            </li>
            <li>
              <div class="form_title">
                归属层级:
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.levelOwner"  placeholder="请设置归属层级">
              </div>
            </li>
            <li>
              <div class="form_title">
                指定下级:
              </div>
              <div class="form_date" @click="showBomListBox(0)">
                <input disabled type="text" v-model="productBaseData.assignNextVersionName" :title="productBaseData.assignNextVersionName"  placeholder="请设置指定下级">
              </div>
            </li>
            <li>
              <div class="form_title">
                上级BOM:
              </div>
              <div class="form_date">
                <input disabled type="text" v-model="productBaseData.assignUpVersionName" :title="productBaseData.assignUpVersionName" placeholder="请设置上级BOM">
              </div>
            </li>
          </ul>
        </div>

        <!-- 单据选项卡 -->
        <div class="productplanTab" id="bomSetTab">
          <el-tabs v-model="bomTabIndex" type="border-card">
            <el-tab-pane label="物料清单" name="0">
              <!-- 物料清单头部按钮 -->
              <div class="createtab_head">
                <el-button type="primary" size="small" @click="show_addMaterialBox(-1)">添加物料</el-button>
              </div>
              <!-- 物料清单表格 -->
              <div class="createtab_table billing_table">
                <el-table :data="tableData_material" :indent="18" :expand-row-keys="defaultUnfold"
                  :tree-props="{children: 'children', hasChildren: 'hasChildren'}" row-key="id" height="100%" border
                  show-summary style="width: 100%" :default-expand-all="true">
                  <!-- 操作 -->
                  <el-table-column label="" min-width="50">
                    <!-- 自定义表头 -->
                    <template slot="header" slot-scope="scope">
                      <el-tooltip placement="bottom-start" effect="light">
                        <div slot="content">
                          <ul class="billsList_relvanceTips scollStyle">
                            <li v-for="elem in showListFiledData" v-if="elem.classify == -1">
                              <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                            </li>
                          </ul>
                        </div>
                        <i class="el-icon-setting"></i>
                      </el-tooltip>
                    </template>
                    <!-- 自定义数据 -->
                    <template slot-scope="scope">
                      <el-dropdown trigger="click" placement="bottom" @command="handleBomOperate">
                        <span class="el-dropdown-link">
                          <i class="el-icon-setting el-icon--right"></i>
                        </span>
                        <el-dropdown-menu slot="dropdown">
                          <el-dropdown-item icon="el-icon-edit-outline" :command="beforeHandleCommand(0,scope.row)">修改
                          </el-dropdown-item>
                          <el-dropdown-item icon="el-icon-circle-plus-outline"
                            :command="beforeHandleCommand(1,scope.row)">添加子级</el-dropdown-item>
                          <el-dropdown-item icon="el-icon-circle-plus" :command="beforeHandleCommand(2,scope.row)">添加替换
                          </el-dropdown-item>
                          <el-dropdown-item icon="el-icon-delete" :command="beforeHandleCommand(3,scope.row)">删除
                          </el-dropdown-item>
                        </el-dropdown-menu>
                      </el-dropdown>
                    </template>
                  </el-table-column>
                  <!-- 数据内容 -->
                  <el-table-column label="物料基本信息" header-cell-style="background:#f11">
                    <el-table-column prop="product_info_code" label="物料编码" width="150" v-if="showListFiledArr.includes(0)"
                      show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="product_old_code" label="旧物料编码" width="150"
                      v-if="show_oldMaterialCode && showListFiledArr.includes(14)" show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="product_info_name" label="物料名称" width="150" v-if="showListFiledArr.includes(1)"
                      show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="specifications" label="物料规格" width="200" v-if="showListFiledArr.includes(2)"
                      show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="calculate_name" label="计量单位" width="80" v-if="showListFiledArr.includes(3)"
                      show-overflow-tooltip>
                    </el-table-column>
                    <el-table-column prop="need_num" label="所需数量" width="80" v-if="showListFiledArr.includes(4)"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column label="未分配数量" width="80" v-if="showListFiledArr.includes(5)" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span class="importent">{{scope.row.not_allot_num}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column label="已分配数量" width="80" v-if="showListFiledArr.includes(6)" show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span class="importent">{{scope.row.allot_num}}</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="consume_rate" label="报损率" width="100" v-if="showListFiledArr.includes(9)"
                      show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span>{{scope.row.consume_rate}}‰</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="position_num" label="位号" width="100" v-if="showListFiledArr.includes(10)"
                      show-overflow-tooltip></el-table-column>
                  </el-table-column>
                  <el-table-column label="物料费用信息">
                    <el-table-column prop="price" label="成本价" width="100" v-if="showListFiledArr.includes(7)"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column prop="total_amount" label="费用" width="100" v-if="showListFiledArr.includes(8)"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column prop="machine_price" label="加工费单价" width="100" v-if="showListFiledArr.includes(10)"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column prop="process_unit_name" label="加工单位" width="100" v-if="showListFiledArr.includes(10)"
                      show-overflow-tooltip></el-table-column>
                    <el-table-column prop="valuation_num" label="计价数" width="100" v-if="showListFiledArr.includes(10)"
                      show-overflow-tooltip></el-table-column>
                  </el-table-column>
                  <el-table-column label="物料其它信息">
                    <el-table-column prop="is_pour_flush" label="倒冲" width="80" v-if="showListFiledArr.includes(11)"
                      show-overflow-tooltip>
                      <template slot-scope="scope">
                        <el-checkbox :value="scope.row.is_pour_flush == 1" :disabled="true"></el-checkbox>
                      </template>
                    </el-table-column>
                    <el-table-column prop="" label="可替换物料" width="100" v-if="showListFiledArr.includes(12)"
                      show-overflow-tooltip>
                      <template slot-scope="scope">
                        <span class="replaceBomTip" v-if="scope.row.replace && scope.row.replace.length>0"
                          @click="showReplaceBox(scope.row)">已设置{{scope.row.replace.length}}个物料</span>
                        <span v-else>-</span>
                      </template>
                    </el-table-column>
                    <el-table-column prop="remark" label="备注" min-width="150" v-if="showListFiledArr.includes(13)"
                      show-overflow-tooltip></el-table-column>
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>

            <!-- 生产工序 -->
            <el-tab-pane label="生产工序" name="1">
              <!-- 物料清单头部按钮 -->
              <div class="createtab_head">
                <el-button type="primary" size="small" @click="showProcessBox">添加工序</el-button>
                <el-button type="primary" size="small" @click="processExPort" v-if="tableData_process.length>0 && operationState != 0  ">导出工序数据</el-button>
              </div>
              <!-- 表格数据 -->
              <div class="createtab_table billing_table">
                <el-table :data="tableData_process" height="100%" border show-summary style="width: 100%">
                  <!-- 操作 -->
                  <el-table-column label="" width="50">
                    <!-- 自定义表头 -->
                    <template slot="header" slot-scope="scope">
                      <el-tooltip placement="bottom-start" effect="light">
                        <div slot="content">
                          <ul class="billsList_relvanceTips scollStyle">
                            <li v-for="elem in showProcessListFiledData" v-if="elem.classify == -1">
                              <el-checkbox v-model="elem.is_show">{{elem.name}}</el-checkbox>
                            </li>
                          </ul>
                        </div>
                        <i class="el-icon-setting"></i>
                      </el-tooltip>
                    </template>
                    <!-- 自定义内容 -->
                    <template slot-scope="scope">
                      <i class="el-icon-delete icon" title="删除" @click="delectProcess(scope.$index,scope.row.id)"></i>
                    </template>
                  </el-table-column>
                  <!-- 数据内容 -->
                  <el-table-column prop="name" sortable label="工序名称" width="120"
                    v-if="showProcessListFiledArr.includes(0)">
                  </el-table-column>
                  <el-table-column prop="" label="物料设置" width="150" v-if="showProcessListFiledArr.includes(1)">
                    <template slot-scope="scope">
                      <span class="processNoSet" v-if="scope.row.assignMaterial.length == 0"
                        @click="showAssignBomBox(scope.row)">未设置物料</span>
                      <span class="processYesSet" v-else
                        @click="showAssignBomBox(scope.row)">已设{{scope.row.assignMaterial.length}}种物料</span>
                    </template>
                  </el-table-column>
                  <el-table-column label="合成物料" width="150" v-if="showProcessListFiledArr.includes(10)"
                    show-overflow-tooltip>
                    <template slot-scope="scope">
                      <div v-if="scope.row.mesBomType!=null">
                        <!-- bom主表 -->
                        <div v-if="scope.row.mesBomType == 0">
                          <el-tooltip placement="right">
                            <div slot="content">
                              <ul>
                                <li>物料编号:{{scope.row.mesMainBom.product_info_code}}</li>
                                <li>物料名称:{{scope.row.mesMainBom.product_info_name}}</li>
                                <li>物料规格:{{scope.row.mesMainBom.specifications}}</li>
                              </ul>
                            </div>
                            <span>{{scope.row.mesMainBom.product_info_name}}</span>
                          </el-tooltip>
                        </div>
                        <!-- bom子表 -->
                        <div v-if="scope.row.mesBomType == 1">
                          <el-tooltip placement="right">
                            <div slot="content">
                              <ul>
                                <li>物料编号:{{scope.row.mesMainBomSubclass.product_info_code}}</li>
                                <li>物料名称:{{scope.row.mesMainBomSubclass.product_info_name}}</li>
                                <li>物料规格:{{scope.row.mesMainBomSubclass.specifications}}</li>
                              </ul>
                            </div>
                            <span>{{scope.row.mesMainBomSubclass.product_info_name}}</span>
                          </el-tooltip>
                        </div>
                      </div>
                      <div v-else>
                        <span class="processYesSet">未设置</span>
                      </div>
                    </template>
                  </el-table-column>
                  <el-table-column prop="dept_name" label="负责部门" width="150" v-if="showProcessListFiledArr.includes(2)"
                    show-overflow-tooltip></el-table-column>
                  <el-table-column prop="user_name" label="负责人" width="150" v-if="showProcessListFiledArr.includes(3)"
                    show-overflow-tooltip></el-table-column>
                  <el-table-column prop="manufactureTypeName" label="生产类别" width="80"
                    v-if="showProcessListFiledArr.includes(4)"></el-table-column>
                  <el-table-column prop="cost_type_name" label="计费方式" width="120"
                    v-if="showProcessListFiledArr.includes(5)">
                  </el-table-column>
                  <el-table-column prop="totalCost" label="总成本" width="150" v-if="showProcessListFiledArr.includes(6)">
                  </el-table-column>
                  <el-table-column prop="totalMaterialCost" label="总物料费用" width="150" v-if="showProcessListFiledArr.includes(6)">
                  </el-table-column>
                  <el-table-column prop="totalProcessingCost" label="总物料加工费用" width="150" v-if="showProcessListFiledArr.includes(6)">
                  </el-table-column>
                  <el-table-column prop="total_labour_cost" label="毛生产费用" width="150" v-if="showProcessListFiledArr.includes(6)">
                  </el-table-column>
                  <el-table-column prop="labour_cost" label="工价" width="150" v-if="showProcessListFiledArr.includes(6)">
                  </el-table-column>
                  <el-table-column prop="hours" label="参考用时" width="150" v-if="showProcessListFiledArr.includes(7)">
                  </el-table-column>
                  <el-table-column prop="fileName" label="工序图纸" width="150" v-if="showProcessListFiledArr.includes(8)">
                  </el-table-column>
                  <el-table-column prop="remark" label="备注" min-width="150" v-if="showProcessListFiledArr.includes(9)">
                  </el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
            <el-tab-pane label="工艺标准" name="2">
              <div class="createtab_head">
                <el-button type="primary" size="small" @click="show_technologyfileBox">新增工艺标准</el-button>
              </div>
              <div class="createtab_table billing_table">
                <el-table :data="tableData" height="100%" border show-summary style="width: 100%">
                  <el-table-column label="操作" width="50">
                    <template slot-scope="scope">
                      <i class="el-icon-edit-outline icon"  title="修改" ></i>
                      <i class="el-icon-delete icon" title="删除" @click="removeTech(scope.row)"></i>
                      <i class="el-icon-view operate_icon" @click="showFilePreView(scope.row)"></i>
                    </template>
                  </el-table-column>
                  <el-table-column prop="file_name" label="文件名" sortable width="180"></el-table-column>
                  <el-table-column prop="remark" label="备注" min-width="300"></el-table-column>
                </el-table>
              </div>
            </el-tab-pane>
          </el-tabs>
        </div>

        <!-- 单据底部 -->
        <div class="billingcreate_form">
          <ul class="form_ul">
            <li>
              <div class="form_title">经办人:</div>
              <div class="form_date">
                <input disabled type="text" v-model="UserInfo.user_name">
              </div>
            </li>
            <li v-if="is_audit">
              <div class="form_title">审核人:</div>
              <div class="form_date">
                <input disabled type="text" v-model="auditInfo.user_name">
              </div>
            </li>
          </ul>
        </div>
      </div>

      <!-- 确认按钮 -->
      <span slot="footer" class="dialog-footer">
        <el-button type="primary" v-show="judgeShowOperateBtn(1)" @click="saveSubmit(1)">提交审批</el-button>
        <el-button type="success" v-show="judgeShowOperateBtn(2)" @click="saveSubmit(2)">保存并审核</el-button>
        <el-button type="success" v-show="judgeShowOperateBtn(3)" @click="saveSubmit(3)">确定</el-button>
        <!-- <el-button type="primary" v-show="judgeShowOperateBtn(4)" @click="colse_createBox">清空</el-button> -->
        <el-button type="primary" v-show="judgeShowOperateBtn(5)" @click="updateBomVersionInfo('all')">保存</el-button>
        <el-button @click="colse_createBox">取消</el-button>
      </span>
    </el-dialog>

    <!-- 可替换物料列表数据 -->
    <el-dialog title="可替换物料" :visible.sync="show_replaceMaterial" width="60%" append-to-body>
      <!-- 可替换物料弹框 -->
      <div class="replaceBomBox billing_table">
        <el-table :data="replaceBomData" row-key="product_info_id" height="100%" border style="width: 100%">
          <!-- 操作 -->
          <el-table-column label="操作" width="100">
            <template slot-scope="scope">
              <i class="el-icon-edit-outline icon" title="修改" @click="showUpdateReplaceBox(scope.row)"></i>
              <i class="el-icon-delete icon" title="删除" @click="delectBomData(scope.row)"></i>
            </template>
          </el-table-column>
          <!-- 数据内容 -->
          <!-- <el-table-column prop="number" label="编号" sortable width="150"></el-table-column> -->
          <el-table-column prop="product_info_name" label="名称" sortable width="200"></el-table-column>
          <el-table-column prop="calculate_name" label="计量单位" width="80"></el-table-column>
          <el-table-column prop="need_num" label="所需数量" width="80"></el-table-column>
          <el-table-column prop="price" label="成本价" width="100"></el-table-column>
          <el-table-column prop="total_amount" label="费用" width="100"></el-table-column>
          <el-table-column prop="remark" label="备注" min-width="150"></el-table-column>
        </el-table>
      </div>

      <!-- 添加修改 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_replaceMaterial = false">取 消</el-button>
        <el-button size="small" type="primary" @click="show_replaceMaterial = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 工序设置指定物料弹框 -->
    <el-dialog :visible.sync="show_assignBom" class="padding_top_10" fullscreen append-to-body>
      <!-- dialog头部自定义 -->
      <div slot="title" class="processMaterialBoxHead">
        <!-- 标题 -->
        <div class="title">
          <span>正在为【{{currentProcess.name}}】指定物料</span>
        </div>
        <!-- 工序合成物料 -->
        <div class="set_material">
          <span>合成物料:</span>
          <el-select v-model="curProcessMaterialId" class="select_material" size="small" filterable placeholder="请选择">
            <el-option @click.native="getProcessMaterial('null')" key="-1" label="无" value="null">
            </el-option>
            <el-option v-for="item in materialNoTree" @click.native="getProcessMaterial(item)" :key="item.id"
              :label="item.product_info_name" :value="item.id" v-if="item.is_bom_merge == 1">
            </el-option>
          </el-select>
        </div>
      </div>
      <!-- 指定物料弹框内容 -->
      <div class="assignBomBox" id="assignProcessBox">
        <!-- 左边可指定物料 -->
        <div class="assignLeft">
          <!-- 头部提示信息 -->
          <div class="assignHead">
            <h5>可指定物料</h5>
          </div>
          <!-- 表格内容 -->
          <div class="assignBody billing_table">
            <el-table ref="supplyAssign" :data="materialNoTree" row-key="id" height="100%" border
              @select="getSelectedAssign" style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="35"></el-table-column>
              <!-- 全选 -->
              <el-table-column type="selection" fixed width="35"></el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="product_info_code" label="编码" sortable width="150" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="product_info_name" label="名称" sortable width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="need_num" label="所需总量" width="80"></el-table-column>
              <el-table-column prop="not_allot_num" label="未分配数量" width="80">
                <template slot-scope="scope">
                  <span class="importent">{{scope.row.not_allot_num}}</span>
                </template>
              </el-table-column>
              <!-- <el-table-column prop="allot_num" label="已分配数量" width="80"></el-table-column> -->
              <el-table-column prop="specifications" label="物料规格" width="180" show-overflow-tooltip></el-table-column>
              <el-table-column prop="position_num" label="位号" width="100" show-overflow-tooltip></el-table-column>
              <el-table-column prop="calculate_name" label="计量单位" min-width="60" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </div>

        <!-- 右边已指定物料 -->
        <div class="assignRight">
          <!-- 头部提示信息 -->
          <div class="assignHead">
            <h5>已指定物料</h5>
          </div>
          <!-- 表格内容 -->
          <div class="assignBody billing_table">
            <el-table :data="currentAlreadyAssign" row-key="id" height="100%" border style="width: 100%">
              <!-- 索引 -->
              <el-table-column type="index" fixed width="35"></el-table-column>
              <!-- 数据内容 -->
              <el-table-column prop="product_info_code" label="编码" sortable width="150" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="product_info_name" label="名称" sortable width="120" show-overflow-tooltip>
              </el-table-column>
              <el-table-column label="分配数量" width="120">
                <template slot-scope="scope">
                  <input class="inputNum" type="number" v-model="scope.row.need_num"
                    @change="updateAssignNum(scope.row)" oninput="this.value=this.value.replace(/[^0-9]/g,'')">
                  </el-input>
                </template>
              </el-table-column>
              <el-table-column prop="specifications" label="物料规格" min-width="180" show-overflow-tooltip>
              </el-table-column>
              <el-table-column prop="calculate_name" label="计量单位" min-width="60" show-overflow-tooltip>
              </el-table-column>
            </el-table>
          </div>
        </div>
      </div>

      <!-- 添加修改 -->
      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_assignBom = false">取 消</el-button>
        <el-button size="small" type="primary" @click="getProcessAssignData">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 客户bom设置弹框 -->
    <el-dialog title="指定客户期限" :visible.sync="show_customerBomSet" append-to-body width="50%">
      <!-- 客户设置弹框 -->
      <div class="customerSetBox billing_table">
        <el-table :data="customerBomSetData" row-key="id" height="100%" border style="width: 100%">
          <!-- 索引 -->
          <el-table-column type="index" fixed width="35"></el-table-column>
          <!-- 数据内容 -->
          <el-table-column prop="product_info_code" label="客户名称" sortable width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="product_info_name" label="有效开始日期" width="120" show-overflow-tooltip>
          </el-table-column>
          <el-table-column prop="calculate_name" label="有效截止日期" width="120"></el-table-column>
          <el-table-column prop="remark" label="备注" min-width="80"></el-table-column>
        </el-table>
      </div>

      <span slot="footer" class="dialog-footer">
        <el-button size="small" @click="show_customerBomSet = false">取 消</el-button>
        <el-button size="small" type="primary" @click="show_customerBomSet = false">确 定</el-button>
      </span>
    </el-dialog>

    <!-- 货品选择组件 -->
    <productList @SelectedData="getProductData" :visibleFinished="1" v-if="is_productBox">
    </productList>

    <!-- 物料添加弹框 -->
    <materialAdd :materialAddBox="materialAddBox" :currentMaterial="currentMaterial" :materialInfo="currentBomEdit" :operatedType="0"
      @getMaterData="getMaterData" :operationState="operationState" v-if="materialAddBox"></materialAdd>

    <!-- 工序选择组件 -->
    <technology-list @SelectedData="getTechnologyData" :defaultSelected="selProcessIdArr"
      :defaultSelectType="defaultTechnologyType"></technology-list>

    <!-- 新增工艺标准组件 -->
    <formTechnologyFile @SelectedData="SelectTech" :show_technologyfile="show_technologyfile"></formTechnologyFile>

    <!-- 货品bom版本选择组件 -->
    <listBomVersion :show_BomVersionBox="show_BomVersionBox" :productBaseData="productBaseData" @SelectedData="selectBom" v-if="show_BomVersionBox"></listBomVersion>
 
    <!-- 附件预览组件 -->
    <filePreview :previewFilePath="previewFilePath"></filePreview>
  </div>
</template>

<script>
  // 导入vuex
  import {
    mapMutations,
    mapState,
    mapActions
  } from 'vuex'
  //导入请求
  import api from "@/network/production/production"; //生产相关
  import net from "@/network/system/controls"; //控制参数相关
  import req from "@/network/datum/product"; //货品相关
  import userApi from "@/network/user"; //用户相关

  //选择组件
  // import productList from "@/components/commComponent/list/list_product.vue"; //货品组件
  import technologyList from '@/components/commComponent/list/list_technology.vue'; //工序选择组件
  import materialAdd from '@/components/production/billing/productionPlan/detail_componentItem/item_compontent/material_add.vue'; //物料添加弹框
  import formTechnologyFile from '@/components/commComponent/form/form_technologyFile.vue'; //
  import listBomVersion from '@/components/commComponent/list/list_bom_version.vue';
    import filePreview from '@/components/commComponent/dialog/file_preview.vue'; //附件预览组件


  export default {
    name: 'bomManage_create',
    props: {
      //操作状态(0:新增 1:修改)
      operationState: {
        type: Number,
        default: 0
      },
      //bom信息
      bomProductData: {
        type: Object,
        default () {
          return {}
        }
      },
    },
    data() {
      return {
        //货品信息
        productBaseData: { //货品基本信息
          productName: '', //货品名称
          productCode: '', //货品编码
          productOldCode: '', //货品旧编码
          productSpecifal: '', //货品规格
          productVersion: '', //货品版本号
          productDate: '', //版本日期
          productProperty: '', //货品bom属性
          productPropertyId: 0, //货品bom属性id
          productModel: '', //货品引用模板
          productCustomerSet: '', //货品客户设置
          totalCostPrice:'',//总成本
          totalMaterialCost:'',//总材料费用
          totalGrossCost:'',//毛生产费用
          isChildCost:false,//是否含归属子级成本(0:否  1:是)
          levelAlone:0,//独有层级
          levelOwner:0,//归属层级
          assignNextVersionId:'',//指定下级货品bom版本id
          assignNextVersionName:'',//指定下级货品bom版本名称
          assignUpVersionId:'',//指定上级货品bom版本id
          assignUpVersionName:'',//指定上级货品bom版本名称
        },
        currentProductInfo: {}, //当前添加bom的货品信息
        levelAloneNum:[],//独有层级数组 选择最大层级
        //物料清单信息
        tableData_material: [], //物料清单表格数据信息
        currentParentId: 1, //当前父级id(用于新增物料信息)
        currentId: 0, //当前物料id（用于新增可替换物料信息）
        currentBomChild: {}, //当前操作的子物料信息(子级用)
        currentBomEdit: {}, //当前编辑的物料信息(所有层级都用)
        replaceBomData: [], //可替换物料信息列表
        showListFiledData: [], //列表可显示的字段
        showListFiledArr: [], //列表需要显示的


        defaultAssignSelected:[],// 下级默认选中列表

        //工序数据信息
        tableData_process: [], //工序数据表格
        selProcessIdArr: [], //已选的工序id数组
        defaultTechnologyType: 0, //工序组件复选框操作类型
        materialNoTree: [], //可指定物料(非树形结构)
        currentAlreadyAssign: [], //当前工序指定物料列表
        currentProcess: {}, //当前操作的工序信息
        showProcessListFiledData: [], //列表可显示的字段
        showProcessListFiledArr: [], //列表需要显示的
        curProcessMaterialId: "", //当前工序指定的合成物料
        currentOtherAssign:[],//当前指定物料的物料全部信息
        currentPorcessId:'',//当前操作工序id

        //可替换物料相关
        replaceParentData: {}, //可替换物料父级数据

        //工艺数据信息
        tableData: [], //工艺数据表格

        // bom指定客户设置
        customerBomSetData: [], //客户设置数据

        //控制开关
        show_replaceMaterial: false, //控制显示可替换物料弹框信息
        show_oldMaterialCode: false, //控制是否显示旧物料编码
        show_technologyfile: false, //(可删)
        show_assignBom: false, //控制显示为工序指定物料弹框
        show_bomProperty: false, //控制是否显示bom属性下拉框
        show_customerBomSet: false, //控制是否显示客户指定bom设置弹框
        watchNum:0,//控制第一次不置空

        //辅助数据
        bomTabIndex: '0', //物料tab页下标
        is_productBox: false, //是否加载货品选择弹框
        materialAddBox: false, //控制新增物料弹框是否显示
        materialOperateState: -1, //物料操作状态(-1:新增界面新增主bom -2:新增界面)
        currentMaterial: -1, //当前操作物料id(-1代表新增物料(包含子级),-2代表添加可替换,其他代表修改)
        operateAddManageParent: -1, //新增物料父级id
        defaultUnfold: [], //默认展开数据

        // 审核信息
        auditInfo: {}, //企业设置的审核人信息
        is_audit: true, //判断BOM审核是否开启(默认开启)

        //货品bom组件列表版本相关
        show_BomVersionBox:false,//控制bom弹框显示(默认不显示)

        //loading框
        loading: '', //loading框

        previewFilePath: '', //附件预览路径
      }
    },
    computed: {
      ...mapState({
        UserInfo: state => state.user.UserInfo, //用户信息
        show_billsCreateBox: state => state.billing.show_billsCreateBox, //控制显示新建弹框组件
        bomPropertyData: state => state.system.bomPropertyData, //bom属性数据
        personnelData: state => state.hr.personnelData, //企业所有员工信息
        companyparamlist: state => state.companyparamlist, //企业级权限参数
        bomMaterialListFileData: state => state.bill_list_config.bomMaterialListFileData, //bom新建物料列表可显示字段
        bomProcessListFileData: state => state.bill_list_config.bomProcessListFileData, //bom新建工序列表可显示字段
        bomProcessExportKeys: state => state.system.bomProcessExportKeys, //bom导出相关字段
      }),
    },
    mounted() {
      // 判断界面显示数据
      this.judgeShowData();

    },
    watch: {
      // 监听当前界面加载
      show_billsCreateBox(newVal) {
        // 判断是打开还是关闭
        if (newVal) { //显示
          this.initBomData(this.bomProductData.id);
          //初始化物料列表显示字段数据
          this.initListShowFiledData();
          //初始化工序列表显示字段数据
          this.initProcessListShowFiledData();

        } else { //关闭
          //初始化data中的数据
          Object.assign(this.$data, this.$options.data());
        }
      },
      // 监听显示列表数据发生变化
      showListFiledData: {
        handler(newVal) {
          this.getCurShowFiledArr();
        },
        deep: true
      },
      // 监听显示列表数据发生变化
      showProcessListFiledData: {
        handler(newVal) {
          this.getProcessCurShowFiledArr();
        },
        deep: true
      },
      //监听物料列表发生变化
      tableData_material:{
        handler(newVal) {
          this.uniqueHierarchy();
        },
         deep: true
      },
      //监听已设物料变化
      currentAlreadyAssign:{
        immediate:true,
        handler(newVal) {
          this.CalculateOtherCost();
        },
         deep: true
      }
    },
    methods: {
      ...mapMutations([
        'SHOW_BILLSCREATEBOX', //控制新建弹框是否显示
        'SHOW_PRODUCTBOX', //控制货品弹框显示
        'SHOW_TECHNOLOGYBOX', //控制工序弹框
        'SHOW_FILEPREVIEW', //控制显示附件预览弹框
      ]),
       /*===============计算相关=============== */
       attributedCost(){
         this.productBaseData.totalCostPrice=''
         this.productBaseData.totalMaterialCost=''
         this.productBaseData.totalGrossCost=''
       },
       /*计算总材料费用*/
       CalculateTotalMaterialCost(){
         this.productBaseData.totalMaterialCost=0
         if(this.productBaseData.isChildCost){
           let sonNum=0
              let data={
                ids:this.productBaseData.assignNextVersionId
              }
              api.calculateTotalMaterialCost(data).then(res=>{
                if (res.code == 200) {
                 sonNum=res.data[0]
                  this.calculateTotalMaterialCost(this.tableData_material)
                 this.productBaseData.totalMaterialCost=this.productBaseData.totalMaterialCost+sonNum
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })

         }else{
           this.calculateTotalMaterialCost(this.tableData_material)
         }
         this.productBaseData.totalMaterialCost=Number(this.productBaseData.totalMaterialCost.toFixed(6))
       },
       /*计算总物料加工费包含子级*/
       calculateTotalMaterialCost(data){
         let totalNum=0 //总物料成本
          let processCostNum=0//总物料加工费

          data.forEach((item,index)=>{
            // console.log(data)
             totalNum=totalNum+item.total_amount
             processCostNum=processCostNum+(item.valuation_num*item.machine_price*item.need_num)
             if(!!item.children&&item.children.length>0){
               this.calculateTotalMaterialCost(item.children)
             }
          })
         // console.log(this.productBaseData.totalMaterialCost)
         this.productBaseData.totalMaterialCost=this.productBaseData.totalMaterialCost+totalNum+processCostNum

       },
       /*计算毛产生费用*/
       grossExpenses(){
         this.productBaseData.totalGrossCost=0
         let grossNum=0
         if(this.productBaseData.isChildCost){
           let sonNum=0
              let data={
                ids:this.productBaseData.assignNextVersionId
              }
              api.calculateTotalMaterialCost(data).then(res=>{
                if (res.code == 200) {
                 sonNum=res.data[1]
                 this.tableData_process.forEach((item,index)=>{
                   grossNum=grossNum+item.total_labour_cost
                 })
                 this.productBaseData.totalGrossCost=grossNum+sonNum
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
         }else{
           this.tableData_process.forEach((item,index)=>{
             grossNum=grossNum+item.total_labour_cost
           })
           this.productBaseData.totalGrossCost=grossNum
         }
       this.productBaseData.totalGrossCost=Number(this.productBaseData.totalGrossCost.toFixed(6))
       },
       /*计算总成本*/
       CalculateTotalCost(){
         if(!!(this.productBaseData.totalMaterialCost).toString() && !! (this.productBaseData.totalGrossCost).toString()){
          this.productBaseData.totalCostPrice =this.productBaseData.totalMaterialCost+this.productBaseData.totalGrossCost
         }else{
           this.$message({
             type: 'warning',
             message: '请先生成总材料费用和毛产生费用',
             duration: this.elDuration
           })
           return
         }
       },
      /* 判断界面需要显示的权限数据 */
      judgeShowData() {
        //判断是否显示旧物料编码
        if (this.companyparamlist.param345 == "0") { //启用
          this.show_oldMaterialCode = true;
        }
      },
      /*当物料设置改变计算相关费用*/
      CalculateOtherCost(){

        this.currentOtherAssign=[]//清空指定物料全部元素
        this.currentPorcessId=''//清空当前操作工序id
        let totalCost=0//总成本
        let totalMaterialCost=0//总物料费用
        let totalProcessingCost=0//总物料加工费
        let currentList=[]
        //调用递归的方法查找出相关的物料
        this.addcurrentOtherassign(this.tableData_material);
        // console.log(this.tableData_process)
        // console.log(this.currentOtherAssign)
        // console.log(this.currentPorcessId)
        this.tableData_process.forEach((itemI,indexI)=>{
          if(itemI.id==this.currentPorcessId){
            if(!!this.currentOtherAssign&&this.currentOtherAssign.length>0){
              this.currentOtherAssign.forEach((item,index)=>{
                 totalMaterialCost=totalMaterialCost+item.total_amount
                 totalProcessingCost=totalProcessingCost+(item.valuation_num*item.machine_price*this.currentAlreadyAssign[index].need_num)
              })
            }
            itemI.totalCost=totalMaterialCost+totalProcessingCost+itemI.total_labour_cost
            itemI.totalMaterialCost=totalMaterialCost
            itemI.totalProcessingCost=totalProcessingCost
          }
        })

        // this.tableData_process.totalCost=
        // this.tableData_process.totalMaterialCost=
        // this.tableData_process.totalProcessingCost=
      },
      //将指定物料的全部信息放入
      addcurrentOtherassign(data){
        let id=[]//存放已指定物料id
        // console.log(this.currentAlreadyAssign)
        this.currentAlreadyAssign.forEach((itemI,indexI)=>{
          id.push(itemI.bom_id)
        })
        if(this.currentAlreadyAssign.length>0){
          this.currentPorcessId=this.currentAlreadyAssign[0].process_id
        }
        data.forEach((item,index)=>{
          if(id.includes(item.id)){
            this.currentOtherAssign.push(item)
          }
          if(!!item.children&&item.children.length>0){
            this.addcurrentOtherassign(item.children)
          }
        })
      },
      /* 初始化bom信息 */
      initBomData(id) {
        //判断当前操作界面
        if (this.operationState == 0) { //新增
          //判断是否开启审核
          if (this.companyparamlist.param264 == "0") { //开启审核
            this.is_audit = true;
            // 判断有无设置审核人
            if (!this.companyparamlist.param285) {
              this.$message({
                type: 'error',
                message: 'bom已开启审核,但未设置审核人,请联系管理员确认!',
                duration: this.elDuration
              })
              return
            }
            //获取用户信息
            let userData = {
              userId: parseInt(this.companyparamlist.param285.split("@@@")[0])
            }
            userApi.user.findUserById(userData).then(res => {
              if (res.code == 200) {
                this.auditInfo = res.data;
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else if (this.companyparamlist.param264 == "1") { //不开启审核
            //设置审核人为开启模式
            this.is_audit = false;
          }

          //设置默认数据
          this.productBaseData.productProperty = this.bomPropertyData[0].name; //bom属性名称
          this.productBaseData.productPropertyId = this.bomPropertyData[0].id; //bom属性id
          this.productBaseData.productDate = this.commonJsExtend.getDateTime(new Date(), 0); //bom日期
        } else if (this.operationState == 1) { //修改
          this.getBomInfoByProductId(id, 0, 1);
        }
      },

      /* 显示附件预览弹框 */
      showFilePreView(data) {
        //获取预览路径
        this.previewFilePath = data.file_name + "@@@" + data.file_url;
        console.log(this.previewFilePath)
        //显示弹框
        this.SHOW_FILEPREVIEW(true);
      },

      /* 修改bom版本基础信息 */
      updateBomVersionInfo(fildName) {

        //判断新增界面不执行改方法
        if (this.operationState == 0) {
          return
        }
        //定义修改的数据
        let updateInfo = {
          id: this.bomProductData.listVersion.id, //需要修改的版本id
        }
        //定义修改的字段名称
        let updateFileName = "";
        // 判断修改的那个字段
        if (fildName == "productVersion") { //版本号
          updateFileName = "版本号";
          if (this.productBaseData.productVersion.trim() == "") {
            this.$message({
              type: "warning",
              message: "版本号不能为空",
              duration: this.elDuration
            })
            return
          }
          updateInfo.version = this.productBaseData.productVersion;
        } else if (fildName == "productProperty") { //版本属性
          updateFileName = "版本属性";
          updateInfo.versionProperty = this.productBaseData.productPropertyId;
        } else if (fildName == "productDate") { //版本日期
          updateFileName = "版本日期";
          updateInfo.bomDate = this.productBaseData.productDate;
        }else if(fildName == "all"){
          //判断是否有进行成本价和总材料费用的计算(20221115徐总确认 计算相关控制放开)
          // if(!!!this.productBaseData.totalCostPrice.toString() || !!!this.productBaseData.totalMaterialCost.toString()
          // || !!!this.productBaseData.totalGrossCost.toString()){
          //   this.$message({
          //     type: 'warning',
          //     message: '请先计算相关费用',
          //     duration: this.elDuration
          //   })
          //   return false
          // }
          updateInfo.totalCostPrice=!!this.productBaseData.totalCostPrice?this.productBaseData.totalCostPrice:0;
          updateInfo.totalMaterialCost=!!this.productBaseData.totalMaterialCost?this.productBaseData.totalMaterialCost:0;
          updateInfo.totalGrossCost=!!this.productBaseData.totalGrossCost?this.productBaseData.totalGrossCost:0;
          updateInfo.isChildCost=this.productBaseData.isChildCost?1:0;
          updateInfo.levelAlone=this.productBaseData.levelAlone;
          updateInfo.levelOwner=this.productBaseData.levelOwner;
          updateInfo.assignNextVersionId=this.productBaseData.assignNextVersionId;
          updateInfo.assignNextVersionName=this.productBaseData.assignNextVersionName;
          updateInfo.productId=this.bomProductData.id
          updateInfo.assignUpVersionId=this.productBaseData.assignUpVersionId;
          //顺便一起处理工艺标准
          updateInfo.mesMainTech=JSON.stringify(this.tableData), //工艺标准
          //发送请求
          api.updateBomVersionInfo(updateInfo).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: "信息修改成功",
                duration: this.elDuration
              })
              this.colse_createBox()
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })

        }

        if(fildName != "all"){

          //加载loading框
          // this.loading = this.commonJsExtend.customLoading("#addBomBox", 4, 'bom信息修改中,请稍候...');
          //发送请求
          api.updateBomVersionInfo(updateInfo).then(res => {
            // this.loading.close();
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: "【" + updateFileName + "】信息修改成功!",
                duration: this.elDuration
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 根据货品id查询bom信息 type(0:初始化,不需要提示信息) classify(0:物料 1:工序 2:工艺)*/
      getBomInfoByProductId(id, type, classify) {
        let data = {
          product_info_id: id, //设置bom的货品id
          version_id: this.bomProductData.listVersion.id, //bom版本
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#addBomBox", 4, 'bom信息获取中,请稍候...');
        //发送请求
        api.findAllMesMainBom(data).then(res => {
          this.loading.close();
          if (res.code == 200) {
            //1.获取货品信息
            this.productBaseData.productName = res.data.productInfo.name; //货品名称
            this.productBaseData.productCode = res.data.productInfo.product_code; //货品编码
            this.productBaseData.productOldCode = res.data.productInfo.product_old_code; //货品旧编码
            this.productBaseData.productSpecifal = res.data.productInfo.specifications; //货品规格
            this.currentProductInfo = res.data.productInfo; //货品基本信息
            this.productBaseData.totalCostPrice=(!!res.data.mesBomVersion.totalCostPrice?res.data.mesBomVersion.totalCostPrice:'');//总成本
            this.productBaseData.totalMaterialCost=(!!res.data.mesBomVersion.totalMaterialCost?res.data.mesBomVersion.totalMaterialCost:'');//总材料费用
            this.productBaseData.totalGrossCost=(!!res.data.mesBomVersion.totalGrossCost?res.data.mesBomVersion.totalGrossCost:'');//毛生产费用
            this.productBaseData.isChildCost=res.data.mesBomVersion.isChildCost==1?true:false;//是否含归属子级成本(0:否  1:是)
            this.productBaseData.levelAlone=res.data.mesBomVersion.levelAlone;//独有层级
            this.productBaseData.levelOwner=res.data.mesBomVersion.levelOwner;//归属层级
            this.productBaseData.assignNextVersionId=res.data.mesBomVersion.assignNextVersionId;//指定下级货品bom版本id
            this.productBaseData.assignNextVersionName=res.data.mesBomVersion.assignNextVersionName;//指定下级货品bom版本名称
            this.productBaseData.assignUpVersionId=res.data.mesBomVersion.assignUpVersionId;//指定上级货品bom版本id
            this.productBaseData.assignUpVersionName=res.data.mesBomVersion.assignUpVersionName;//指定上级货品bom版本名称
            //2.获取版本信息
            this.productBaseData.productVersion = res.data.mesBomVersion.version; //bom版本号
            this.productBaseData.productPropertyId = res.data.mesBomVersion.versionProperty; //bom属性id
            this.productBaseData.id=res.data.mesBomVersion.id;//版本id
            let bompropertyArr = this.bomPropertyData.filter(item => item.id == res.data.mesBomVersion
              .versionProperty);
            if (bompropertyArr.length > 0) {
              this.productBaseData.productProperty = bompropertyArr[0].name; //bom属性名称
            }
            this.productBaseData.productDate = res.data.mesBomVersion.bomDate; //bom设置日期
            //3.获取物料信息
            let materialData = JSON.parse(JSON.stringify(res.data.mesMainBomList));
            //3.1获取物料非树形结构
            let materialNoTreeData = this.commonJsExtend.toNoTree(materialData, []);
            //3.2获取货品其他需要显示的字段信息
            materialNoTreeData.forEach((item, index) => {
              if(item.productInfo){
                //获取旧物料编码
                item.product_old_code = item.productInfo.product_old_code;
                //判断合成物料
                if(item.productInfo.proClassifyIsBomMerge == 1){
                  item.is_bom_merge = 1;
                }else{
                  if(item.productInfo.is_bom_merge == 1){
                    item.is_bom_merge = 1;
                  }else{
                    item.is_bom_merge = 0;
                  }
                }
              }

              //获取层级id
              if (!item.hasOwnProperty('mes_bom_parent_id')) { //顶级
                item.parentid = -1;
              } else {
                if (item.mes_bom_parent_id = -1) {
                  item.parentid = item.mes_main_bom_id;
                } else {
                  item.parentid = item.mes_bom_parent_id;
                }
              }
            })
            // 3.3转换为树形结构
            this.tableData_material = this.commonJsExtend.toTree(materialNoTreeData, 'parentid');

            //4.获取工序信息
            this.tableData_process = res.data.mesMainProcessList;
            //5.获取工艺信息
            this.tableData = res.data.mesMainTechList;
            //显示提示信息(修改界面才会使用)
            let message = "";
            if (type == 1) { //新增后查询
              if (classify == 0) {
                message = "物料添加成功!";
              } else if (classify == 1) {
                message = "工序添加成功!";
              } else if (classify == 2) {
                message = "工艺添加成功!";
              }
            } else if (type == 2) { //修改后查询
              if (classify == 0) {
                message = "物料修改成功!";
              } else if (classify == 1) {
                message = "工序修改成功!";
              } else if (classify == 2) {
                message = "工艺修改成功!";
              }
            } else if (type == 3) { //删除后查询
              if (classify == 0) {
                message = "物料删除成功!";
              } else if (classify == 1) {
                message = "工序删除成功!";
              } else if (classify == 2) {
                message = "工艺删除成功!";
              }
            }
            if (type != 0) {
              this.$message({
                type: "success",
                message: message,
                duration: this.elDuration
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })

      },

      /* 显示货品选择弹框 */
      show_getProductbox() {
        //控制加载货品弹框
        this.is_productBox = true;
        //控制显示货品弹框
        this.SHOW_PRODUCTBOX(true);
      },

      /*显示bom弹框*/
      showBomListBox(){
        this.show_BomVersionBox=true;
      },

      /*关闭bom弹框*/
      closeBomListBox(){
        this.show_BomVersionBox=false;
      },


      /* 获取产品选择组件的数据 */
      getProductData(data) {
        if (data && data.length > 1) {
          this.$message({
            type: 'warning',
            message: '一次只能选择一个货品',
            duration: this.elDuration
          })
          return false
        }
        //将所选货品信息存入
        this.currentProductInfo = data[0]; //获取基本信息
        this.productBaseData.productName = data[0].name; //获取货品名称
        this.productBaseData.productCode = data[0].product_code; //获取货品编码
        this.productBaseData.productOldCode = data[0].product_old_code; //获取货品旧编码
        this.productBaseData.productSpecifal = data[0].specifications; //获取货品规格
        //控制不加载货品弹框
        this.is_productBox = false;
      },

      /* 获取选中的bom属性 */
      getBomProperty(data) {
        //获取当前选择的属性数据
        this.productBaseData.productProperty = data.name;
        this.productBaseData.productPropertyId = data.id;
        //关闭下拉框
        this.show_bomProperty = false;
        //判断当前操作界面
        if (this.operationState == 1) { //修改界面
          //调用修改方法
          this.updateBomVersionInfo('productProperty');
        }
      },

      /* 切换操作之前触发的事件 */
      beforeHandleCommand(index, data) {
        return {
          'index': index, //下标
          'data': data //数据
        }
      },

      /* 处理操作方式 */
      handleBomOperate(val) {
        if (val.index == 0) { //修改
          this.updateCurrentBomData(val.data);
        } else if (val.index == 1) { //添加子级
          this.show_addMaterialSubclassBox(val.data);
        } else if (val.index == 2) { //添加可替换物料
          this.addReplaceBom(val.data);
        } else if (val.index == 3) { //删除
          this.delectBomData(val.data);
        }
      },

      /* 显示新增物料弹框(顶级) */
      show_addMaterialBox(parentid) {
        //将数据存入data
        this.operateAddManageParent = parentid; //父id
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置弹框类型为新增
        this.currentMaterial = -1;
      },

      /* 显示添加子级物料弹框(直接利用深拷贝处理) */
      show_addMaterialSubclassBox(data) {
        //将数据存入data
        this.operateAddManageParent = data.id; //父id
        //显示新增物料弹框
        this.materialAddBox = true;
        //获取操作的子级物料数据
        this.currentBomChild = data;
        //设置弹框类型为新增
        this.currentMaterial = -1;
      },

      /* 添加可替换物料信息 */
      addReplaceBom(data) {
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置弹框类型为添加同级物料(可替换物料)
        this.currentMaterial = -2;
        //当前操作物料id
        this.currentId = data.id;
        //获取当前修改的物料信息
        this.currentBomEdit = data;
      },

      /* 显示可替换物料信息列表 */
      showReplaceBox(data) {
        //显示弹框
        this.show_replaceMaterial = true;
        //判断是新增界面还是修改界面
        if (this.operationState == 0) { //新增界面
          this.replaceBomData = data.replace;
        } else if (this.operationState == 1) { //修改界面
          //获取列表数据
          if (!data.hasOwnProperty('mes_main_bom_id')) {
            this.getReplaceBom(data.id, 0);
          } else {
            this.getReplaceBom(data.id, 1);
          }
        }
      },

      /* 修改当前bom */
      updateCurrentBomData(data) {
        //获取当前修改的物料信息
        this.currentBomEdit = data;
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置弹框类型为修改
        this.currentMaterial = data.id;
      },

      /* 显示修改可替换物料弹框 */
      showUpdateReplaceBox(data) {
        //获取当前修改的物料信息
        this.currentBomEdit = data;
        //显示新增物料弹框
        this.materialAddBox = true;
        //设置弹框类型为修改
        this.currentMaterial = data.id;
      },

      /* 判断显示操作类型按钮type(1:提交审核 2:保存并审核  3:确认(不需要审核时用)) */
      judgeShowOperateBtn(type) {
        //定义返回类型(默认不显示)
        let result = false;
        // 判断按钮类型
        if (type == 1) { //提交审核
          //新增界面且开启审核显示
          if (this.operationState == 0 && this.is_audit) {
            result = true;
          }
        } else if (type == 2) { //保存并审核
          //新增界面且开启审核且当前登录用户为审核用户
          if (this.operationState == 0 && this.is_audit && this.auditInfo.user_id == this.UserInfo.user_id) {
            result = true;
          }
        } else if (type == 3) { //确认按钮
          //新增界面未开启审核
          if (this.operationState == 0 && !this.is_audit) {
            result = true;
          }
        } else if (type == 4) { //清空

        } else if (type == 5) { //保存
          //修改界面
          if (this.operationState == 1) {
            result = true;
          }
        }
        return result;
      },

      /* 获取物料添加弹框数据 */
      getMaterData(data) {
        // console.log(data)

        //定义后端接受公共参数
        let materialData = { //物料信息
          product_info_id: data.proId, //物料id
          product_info_code: data.proCode, //物料编码
          product_old_code: data.proOldCode, //旧物料编码
          product_info_name: data.name, //物料名称
          specifications: data.specifications, //规格型号
          calculate_id: data.unit, //计量单位id
          calculate_name: data.unitName, //计量单位名称
          is_init: 1, //是否为初次选择物料
          need_num: data.number, //所需数量
          allot_num: 0, //已被工序分配的数量(默认为0)
          not_allot_num: data.number, //未分配数量(默认等于所需数量,工序指定物料时用)
          priceData: data.priceData, //价格多计量数据
          price: data.price, //成本单价
          machine_price: data.machine_price, //加工费单价
          process_unit_id: data.process_unit_id, //加工单位id
          process_unit_name: data.process_unit_name, //加工单位名称
          valuation_num: data.valuation_num, //计价数
          position_num: data.positionNum, //位置号
          consume_rate: data.consume_rate, //报损率
          is_pour_flush: data.is_pour_flush == true ? 1 : 0, //是否为倒冲
          is_bom_merge: data.is_bom_merge == true ? 1 : 0, //是否为工序指定合成物料
          is_rate:data.is_rate == true ? 1 : 0,//是否含税
          remark: data.remark, //备注
          total_amount: data.cost, //所需总费用
          //货品信息
          product_id: this.currentProductInfo.id, //需要添加bom的货品id
        }

        //判断是新增还是修改
        if (this.operationState == 0) { //新增界面
          //调用新增界面处理业务方法
          this.addMaterialData(materialData, data);
        } else if (this.operationState == 1) { //修改界面(都是单个调接口处理)
          //调用修改界面处理业务方法
          this.addUpdateMaterialData(materialData);
        }
      },
      //选中bom的数据
      selectBom(data){

        let verSionId=[]
        let assignNext=[]
        let assignNextId=[]
        if(!!data){
          data.forEach((item,index)=>{
            if(!!this.productBaseData.assignUpVersionId){
              if(this.productBaseData.assignUpVersionId.split(",").includes(item.listVersion.id.toString())){
                this.productBaseData.assignNextVersionName=''
                this.productBaseData.assignNextVersionId=''
                this.productBaseData.levelOwner=0
                this.$message({
                  type: 'warning',
                  message: '请不要指定下级包含指定上级',
                  duration: this.elDuration
                })
                return
              }
            }
            verSionId.push(item.listVersion.id)
            assignNext.push(item.name+"("+item.product_code+")")
            assignNextId.push(item.listVersion.id)
          })
          if(this.operationState == 1){
            if(verSionId.splice(",").includes(this.bomProductData.listVersion.id)){
              this.productBaseData.totalCostPrice=''
              this.productBaseData.totalGrossCost=''
              this.productBaseData.totalMaterialCost=''
              this.productBaseData.assignNextVersionName=''
              this.productBaseData.assignNextVersionId=''
              this.productBaseData.levelOwner=0
              this.$message({
                type: 'warning',
                message: '请不要指定本身作为下级',
                duration: this.elDuration
              })
              return
            }
          }
            this.productBaseData.assignNextVersionName=assignNext.join(",")
            this.productBaseData.assignNextVersionId=assignNextId.join(",")
            // 发送请求求得归属层级
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#addBomBox", 4, 'bom信息获取中,请稍候...');
            let  bomData={
              versionId:this.productBaseData.assignNextVersionId
            }
             api.calculationAttributionLevel(bomData).then(res=>{
               this.loading.close();
               if (res.code == 200) {
                   this.productBaseData.levelOwner=res.data
                 }
                 else {
                   this.$message({
                     type: 'error',
                     message: res.message,
                     duration: this.elDuration
                   })
                 }
             })

        }else{
          this.productBaseData.assignNextVersionName=''
          this.productBaseData.assignNextVersionId=''
          this.productBaseData.levelOwner=0
        }

      },
      /* 处理bom新增时的业务materialData:物料基本信息 data:物料弹框返回信息 */
      addMaterialData(materialData, data) {
        // 判断是新增还是修改
        if (this.currentMaterial == -1) { //新增父子级物料
          //设置添加物料信息时需要的其他数据
          materialData.id = this.currentParentId; //物料id(新增整体时用)
          materialData.parentid = this.operateAddManageParent; //父级id
          materialData.children = []; //子级数据
          materialData.replace = []; //可替换数据
          // 判断添加哪一级
          if (this.operateAddManageParent == -1) { //添加顶级
            //循环判断是否有重复的
            this.tableData_material.forEach((item, index) => {
              if (item.product_info_id == data.proId) {
                this.$message({
                  type: 'warning',
                  message: '该物料已存在!',
                  duration: this.elDuration
                })
                return
              }
            })
            //添加数据
            this.tableData_material.push(materialData);
          } else { //添加子级(直接利用深拷贝处理)
            this.currentBomChild.children.forEach((item, index) => {
              if (item.product_info_id == data.proId) {
                this.$message({
                  type: 'warning',
                  message: '该目录下已存在该物料!',
                  duration: this.elDuration
                })
                return
              }
            })
            this.currentBomChild.children.push(materialData);
          }
          //id自增
          this.currentParentId++;
        } else if (this.currentMaterial == -2) { //新增可替换物料
          this.currentBomEdit.replace.forEach((item, index) => {
            if (item.product_info_id == data.proId) {
              this.$message({
                type: 'warning',
                message: '该物料已存在!',
                duration: this.elDuration
              })
              return
            }
          })
          //获取可替换物料数据
          this.currentBomEdit.replace.push(materialData);
          //id自增
          this.currentParentId++;
        } else { //修改
          //获取修改前的其他信息(除物料本身外的信息)
          let currentEdit = JSON.parse(JSON.stringify(this.currentBomEdit))
          //判断修改后的数量和工序已设物料数量是否冲突
          if (materialData.need_num < currentEdit.allot_num) {
            this.$message({
              type: 'warning',
              message: '修改失败,该物料已分配数量大于修改后数量!',
              duration: this.elDuration
            })
            return
          }
          //定义不需要修改的字段(传入的数据不修改)
          let noUpdataFile = ['allot_num'];
          //获取修改后的数据
          for (let i in materialData) {
            if (!noUpdataFile.includes(i)) {
              currentEdit[i] = materialData[i];
            }
          }
          //处理未分配数量
          currentEdit.not_allot_num = materialData.need_num - currentEdit.allot_num;
          //获取无树形结构的数据
          let noTreeBom = this.commonJsExtend.toNoTree(this.tableData_material, []);
          //获取当前操作数据的下标
          let currentIndex = -1;
          noTreeBom.forEach((item, index) => {
            if (this.currentMaterial == item.id) {
              currentIndex = index;
            }
          })
          //替换数据
          noTreeBom.splice(currentIndex, 1, currentEdit);
          //转换为树形结构
          this.tableData_material = this.commonJsExtend.toTree(noTreeBom, 'parentid');
        }
      },

      /* 处理修改bom时的业务 */
      addUpdateMaterialData(data) {
        data.version_id = this.bomProductData.listVersion.id; //当前版本id
        if (this.currentMaterial == -1) { //新增父子级
          // 判断添加哪个表
          if (this.operateAddManageParent == -1) { //添加顶级(主表)
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#bomSetTab", 4, '物料信息添加中,请稍候...');
            //添加主物料请求
            api.addMesMainBomOnly(data).then(res => {
              this.loading.close();
              if (res.code == 200) {
                //调用查询方法
                this.getBomInfoByProductId(this.currentProductInfo.id, 1, 0);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else { //添加子级
            // 判断是子级的一级还是其他级数
            if (this.currentBomChild.hasOwnProperty('mes_main_bom_id')) {
              data.mes_bom_parent_id = this.currentBomChild.id; //父级id
              data.mes_main_bom_id = this.currentBomChild.mes_main_bom_id; //主表id
            } else {
              data.mes_bom_parent_id = -1; //父级id
              data.mes_main_bom_id = this.currentBomChild.id; //主表id
            }
            //加载loading框
            //发送请求
            api.addMesMainBomSubClassOnly(data).then(res => {
              if (res.code == 200) {
                //调用父级查询方法
                this.getBomInfoByProductId(this.currentProductInfo.id, 1, 0)
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        } else if (this.currentMaterial == -2) { //添加可替换物料
          //添加其他信息
          data.relevance_main_bom_id = this.currentId;
          if (!this.currentBomEdit.hasOwnProperty('mes_main_bom_id')) { //添加主表可替换
            //加载loading框
            //发送请求
            api.addMesMainBomReplaceOnly(data).then(res => {
              if (res.code == 200) {
                //调用查询方法
                this.getBomInfoByProductId(this.currentProductInfo.id, 1, 0);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else { //添加子表可替换
            //加载loading框
            //发送请求
            api.addMesMainBomSubClassReplaceOnly(data).then(res => {
              if (res.code == 200) {
                //调用查询方法
                this.getBomInfoByProductId(this.currentProductInfo.id, 1, 0);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        } else { //修改
          if (!this.currentBomEdit.hasOwnProperty('mes_bom_parent_id')) { //修改主表
            //增加其他字段
            data.id = this.currentMaterial;
            //加载loading框
            //发送请求
            api.updateMesMainBom(data).then(res => {
              if (res.code == 200) {
                //判断是修改的主bom还是替换bom
                if (this.currentBomEdit.relevance_main_bom_id == -1) { //主物料
                  //调用查询方法
                  this.getBomInfoByProductId(this.currentProductInfo.id, 2, 0);
                } else { //替换物料
                  this.getReplaceBom(this.currentBomEdit.relevance_main_bom_id, 0);
                }
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else { //修改子表
            //增加其他字段
            data.id = this.currentMaterial;
            //加载loading框
            //发送请求
            api.updateMesMainBomSubClass(data).then(res => {
              if (res.code == 200) {
                //判断是修改的主bom还是替换bom
                if (this.currentBomEdit.relevance_main_bom_id == -1) { //主物料
                  //调用查询方法
                  this.getBomInfoByProductId(this.currentProductInfo.id, 2, 0);
                } else { //替换物料
                  this.getReplaceBom(this.currentBomEdit.relevance_main_bom_id, 1);
                }
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        }
      },

      /* 查询可替换物料信息列表id:关联bom的id  type(0:主表  1:子表) */
      getReplaceBom(id, type) {
        let data = {
          relevanceMainBomId: id,
          type: type,
        }
        //加载loading框
        //发送请求
        api.findReplaceBom(data).then(res => {
          //关闭loading框
          if (res.code == 200) {
            this.replaceBomData = res.data;
          } else {
            this.$message({
              type: 'warning',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 删除Bom物料 */
      delectBomData(data) {
        if (this.operationState == 0) { //新增界面
          // 判断是否已经在分配中(分配中不可删除)
          if (data.need_num != data.not_allot_num) {
            this.$message({
              type: 'warning',
              message: '该物料已在工序中分配,请先去除已分配数据!',
              duration: this.elDuration
            })
            return
          }
          //获取无树形结构的数据
          let noTreeBom = this.commonJsExtend.toNoTree(this.tableData_material, []);
          //获取当前操作数据的下标
          let currentIndex = -1;
          noTreeBom.forEach((item, index) => {
            if (data.id == item.id) {
              currentIndex = index;
            }
          })
          //替换数据
          noTreeBom.splice(currentIndex, 1);
          //转换为树形结构
          this.tableData_material = this.commonJsExtend.toTree(noTreeBom, 'parentid');
        } else if (this.operationState == 1) { //修改界面
          this.$confirm('确定要删除此物料吗,删除的数据不可恢复?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            //判断是主表还是子表
            if (data.hasOwnProperty("mes_bom_parent_id")) { //子表
              //请求
              api.modifyMesMainBomSubClassDisable({
                id: data.id
              }).then(res => {
                if (res.code == 200) {
                  //判断是删除的主bom还是替换bom
                  if (data.relevance_main_bom_id == -1) { //主物料
                    this.getBomInfoByProductId(this.currentProductInfo.id, 3, 0);
                  } else { //替换物料
                    this.getBomInfoByProductId(this.currentProductInfo.id, 3, 0);
                    this.getReplaceBom(data.relevance_main_bom_id, 1);
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else { //父级
              //请求
              api.modifyMesMainBomDisable({
                id: data.id
              }).then(res => {
                if (res.code == 200) {
                  //判断是删除的主bom还是替换bom
                  if (data.relevance_main_bom_id == -1) { //主物料
                    this.getBomInfoByProductId(this.currentProductInfo.id, 3, 0);
                  } else { //替换物料
                    this.getBomInfoByProductId(this.currentProductInfo.id, 3, 0);
                    this.getReplaceBom(data.relevance_main_bom_id, 1);
                  }
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          }).catch(() => {})
        }
      },

      /* 删除可替换物料 */
      deleteReplaceData() {},

      /* 关闭新增物料弹框 */
      closeMaterialBox() {
        this.materialAddBox = false;
      },

      /* 控制是否加载工序选择弹框 */
      showProcessBox() {
        //根据不同界面设置弹框复选框操作类型
        if (this.operationState == 0) { //新增界面
          this.defaultTechnologyType = 0;
        } else if (this.operationState == 1) { //修改界面
          this.defaultTechnologyType = 1;
        }
        //设置默认选中的工序id数组
        this.selProcessIdArr = [];
        this.tableData_process.forEach((item, index) => {
          this.selProcessIdArr.push(item.id);
        })
        //显示弹框
        this.SHOW_TECHNOLOGYBOX(true)
      },

      //工艺选择组件返回数据
      SelectTech(data){
        this.tableData.push(data)
      },

      /* 工序选择组件返回数据 */
      getTechnologyData(data) {
        // 判断当前界面状态
        if (this.operationState == 0) { //新增界面
          data.forEach((item, index) => {
            //默认设置一个已指定物料数组
            this.$set(item, "assignMaterial", []);
            //默认设置一个工序指定合成物料
            this.$set(item, "mesBomType", null); //bom类型
            this.$set(item, "mesMainBom", null); //bom主表
            this.$set(item, "mesMainBomSubclass", null); //bom子表
            //获取工序数据
            this.tableData_process.push(item);
          })
        } else if (this.operationState == 1) { //修改界面
          //获取新选择的工序
          let resIdArr = [];
          data.forEach((item, index) => {
            resIdArr.push(item.id);
          })
          //判断是否有选择工序
          if (resIdArr.length == 0) {
            return
          }
          //定义添加工序后端所需数据
          let addProcessData = {
            version_id: this.bomProductData.listVersion.id, //被设置bom的版本id
            process_ids: resIdArr.join(","),
          }
          //加载loading框
          //发送请求
          api.addMesMainProcessOnly(addProcessData).then(res => {
            if (res.code == 200) {
              //调用查询方法
              this.getBomInfoByProductId(this.currentProductInfo.id, 1, 1);
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 显示为工序设置指定物料弹框 */
      showAssignBomBox(data) {
        if (this.tableData_material.length == 0) {
          this.$message({
            type: 'warning',
            message: '请先设置物料!',
            duration: this.elDuration
          })
          return
        }
        //获取当前操作的工序
        this.currentProcess = data;
        //获取可指定物料数据(非树形结构)(左边数据)
        this.materialNoTree = this.commonJsExtend.toNoTree(this.tableData_material, []);
        // 判断当前界面状态
        if (this.operationState == 0) { //新增
          //获取当前工序已指定物料数据(右边数据)
          this.currentAlreadyAssign = data.assignMaterial;
          //获取工序指定合成物料
          if (data.mesBomType == null) {
            this.curProcessMaterialId = "null";
          } else {
            //判断是主表还是子表
            if (data.mesBomType == 0) { //主表
              this.curProcessMaterialId = data.mesMainBom.id;
            } else if (data.mesBomType == 1) { //子表
              this.curProcessMaterialId = data.mesMainBomSubclass.id;
            }
          }
        } else if (this.operationState == 1) { //修改
          //处理驼峰
          let newAssugnMaterial = [];
          data.assignMaterial.forEach((item, index) => {
            let itemObj = {};
            for (let i in item) {
              let noHump = this.commonJsExtend.toLine(i);
              itemObj[noHump] = item[i];
            }
            newAssugnMaterial.push(itemObj);
          })
          //获取当前工序已指定物料数据(右边数据)
          this.currentAlreadyAssign = newAssugnMaterial;
          //获取工序指定合成物料
          if (data.mesBomType == null) {
            this.curProcessMaterialId = "null";
          } else {
            //判断是主表还是子表
            if (data.mesBomType == 0) { //主表
              this.curProcessMaterialId = data.mesMainBom.id;
            } else if (data.mesBomType == 1) { //子表
              this.curProcessMaterialId = data.mesMainBomSubclass.id;
            }
          }
        }
        //设置默认选择的数据
        this.$nextTick(() => {
          // 获取默认选中的物料id数组
          let selIdArr = [];
          this.currentAlreadyAssign.forEach((item, index) => {
            selIdArr.push(item.bom_id);
          })
          //根据默认选中的id默认勾选对应物料
          this.materialNoTree.forEach((item, index) => {
            if (selIdArr.includes(item.id)) {
              this.$refs.supplyAssign.toggleRowSelection(item, true);
            }
          })
        })

        //显示弹框
        this.show_assignBom = true;
      },

      /* 获取勾选的指定物料 */
      getSelectedAssign(selection, row) {
        //获取当前操作的物料信息
        let assighObj = {
          product_main_id: this.currentProductInfo.id, //关联的货品id
          process_id: this.currentProcess.id, //工序id
          version_id: this.operationState == 1 ? this.bomProductData.listVersion.id : 0, //版本id
          bom_id: row.id, //物料id
          need_num: row.not_allot_num, //所需数量(默认为未分配数量)
          need_all_num: row.need_num, //所需总量
          product_info_id: row.product_info_id, //物料id
          product_info_code: row.product_info_code, //物料编码
          product_old_code: row.product_old_code, //旧物料编码
          product_info_name: row.product_info_name, //物料名称
          specifications: row.specifications, //物料规格
          calculate_id: row.calculate_id, //计量单位id
          calculate_name: row.calculate_name, //计量单位名称
          price: row.price, //物料单价
        }
        // 判断当前操作界面
        if (this.operationState == 0) { //新增界面
          //判断是勾选还是取消
          if (selection.includes(row)) { //勾选
            //获取界面所需其他数据
            assighObj.bom_id_type = row.parentid == -1 ? 0 : 1; //物料类型(0:主表 1:子表)
            //将处理好的数据添加当前工序
            let alreadyExit = [];
            this.currentAlreadyAssign.forEach((item, indexJ) => {
              alreadyExit.push(item.bom_id);
            })
            //已添加的数据不添加
            if (!alreadyExit.includes(assighObj.bom_id)) {
              this.currentAlreadyAssign.push(assighObj);
            }
          } else { //取消
            this.currentAlreadyAssign = this.currentAlreadyAssign.filter(item => item.bom_id != row.id);
          }
        } else if (this.operationState == 1) { //修改界面
          //判断是勾选还是取消
          if (selection.includes(row)) { //勾选
            //获取界面所需其他数据
            if (!row.hasOwnProperty('mes_main_bom_id')) {
              assighObj.bom_id_type = 0;
            } else {
              assighObj.bom_id_type = 1;
            }
            //转换一次驼峰
            let assighObjHump = {};
            for (let i in assighObj) {
              let hump = this.commonJsExtend.toHump(i);
              assighObjHump[hump] = assighObj[i];
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#assignProcessBox", 4, '工序指定物料信息添加中,请稍后...');
            //发送请求
            api.addMesBomProcessNum(assighObjHump).then(res => {
              this.loading.close();
              if (res.code == 200) {
                // 获取更新后的列表信息
                this.getAllMaterialData();
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          } else { //取消
            let deleteId;
            //获取需要删除的物料信息id
            this.currentAlreadyAssign.forEach((item, index) => {
              if (item.bom_id == row.id) {
                deleteId = item.id;
              }
            })
            //定义后端接口数据
            let deleteData = {
              id: deleteId
            }
            //加载loading框
            this.loading = this.commonJsExtend.customLoading("#assignProcessBox", 4, '工序指定物料信息删除中,请稍后...');
            api.deleteMesBomProcessNum(deleteData).then(res => {
              this.loading.close();
              if (res.code == 200) {
                // 获取更新后的列表信息
                this.getAllMaterialData();
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }
        }
      },

      /* 工序指定物料数量发生改变 */
      updateAssignNum(data) {
        if (this.operationState == 1) {
          let updateData = {
            id: data.id, //被修改的数据id
            needNum: data.need_num, //修改后的数量
          }
          //加载loading框
          this.loading = this.commonJsExtend.customLoading("#assignProcessBox", 4, '工序指定物料信息修改中,请稍候...');
          //发送请求
          api.updateMesBomProcessNum(updateData).then(res => {
            this.loading.close();
            if (res.code == 200) {
              this.getAllMaterialData();
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取所有物料信息,所有信息,包括左边右边数据(工序指定物料修改界面使用) */
      getAllMaterialData() {
        let data = {
          product_info_id: this.currentProductInfo.id, //货品id
          version_id: this.bomProductData.listVersion.id, //版本id
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#assignProcessBox", 4, '物料列表获取中,请稍候...');
        //发送请求
        api.findAllMesMainBom(data).then(res => {
          this.loading.close()
          if (res.code == 200) {
            //获取物料信息
            this.tableData_material = res.data.mesMainBomList;
            //获取供工序指定所有物料列表信息
            this.materialNoTree = this.commonJsExtend.toNoTree(this.tableData_material, []);
            //获取工序信息
            this.tableData_process = res.data.mesMainProcessList;
            //获取当前工序指定物料信息
            this.tableData_process.forEach((itemI, indexI) => {
              if (itemI.id == this.currentProcess.id) {
                //处理驼峰
                let newAssugnMaterial = [];
                itemI.assignMaterial.forEach((itemj, indexJ) => {
                  let itemObj = {};
                  for (let i in itemj) {
                    let noHump = this.commonJsExtend.toLine(i);
                    itemObj[noHump] = itemj[i];
                  }
                  newAssugnMaterial.push(itemObj);
                })
                //获取当前工序已指定物料数据(右边数据)
                this.currentAlreadyAssign = newAssugnMaterial;
              }
            })
            //设置默认选择的数据
            this.$nextTick(() => {
              // 获取默认选中的物料id数组
              let selIdArr = [];
              this.currentAlreadyAssign.forEach((item, index) => {
                //处理驼峰
                selIdArr.push(item.bom_id);
              })
              //根据默认选中的id默认勾选对应物料
              this.materialNoTree.forEach((item, index) => {
                if (selIdArr.includes(item.id)) {
                  this.$refs.supplyAssign.toggleRowSelection(item, true);
                }
              })
            })
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 获取工序指定合成物料 */
      getProcessMaterial(data) {
        //判断当前界面状态
        if (this.operationState == 0) { //新增(只需要用主表代替就行)
          // 将当前设置的工序物料信息添加到对应数据
          this.tableData_process.forEach((item, index) => {
            if (item.id == this.currentProcess.id) {
              item.mesBomType = 0;
              item.mesMainBom = data;
            }
          })
        } else if (this.operationState == 1) { //修改界面
          let strArr = [];
          // console.log(this.tableData_process);
          this.tableData_process.forEach((item, index) => {
            //设置当前工序合成物料数据
            if (item.id == this.currentProcess.id) {
              //判断是否选择的无
              if (data == "null") {
                item.mesBomType = "null";
              } else {
                item.mesBomType = data.bomType;
                //判断主表还是子表
                if (data.bomType == 0) {
                  item.mesMainBom = data;
                } else if (data.bomType == 1) {
                  item.mesMainBomSubclass = data;
                }
              }
            }
            //判断是否为空
            if (item.mesBomType == "null" || item.mesBomType == null) {
              strArr.push("null");
            } else {
              if (item.mesBomType == 0) { //主表
                strArr.push(item.mesMainBom.id + "@@@0")
              } else if (item.mesBomType == 1) { //子表
                strArr.push(item.mesMainBomSubclass.id + "@@@1")
              }
            }
          })
          //定义需要修改的数据
          let updateData = {
            version_id: this.bomProductData.listVersion.id,
            processMaterialStr: strArr.join(","),
          }
          //加载loading框
          //发送请求
          api.updateMesMainBomProcessMaterialStr(updateData).then(res => {
            if (res.code == 200) {
              this.$message({
                type: 'success',
                message: "工序指定合成物料修改成功",
                duration: this.elDuration
              })
            } else {
              this.$message({
                type: 'error',
                message: res.message,
                duration: this.elDuration
              })
            }
          })
        }
      },

      /* 获取工序指定物料 */
      getProcessAssignData() {
        //判断当前界面操作状态
        if (this.operationState == 0) { //新增
          //判断分配的物料数量数否合法
          if (!this.judgeAllocationNum()) {
            return
          }
          // 将当前设置的工序物料信息添加到对应数据
          this.tableData_process.forEach((item, index) => {
            if (item.id == this.currentProcess.id) {
              item.assignMaterial = JSON.parse(JSON.stringify(this.currentAlreadyAssign));
            }
          })
          //获取未分配物料数据(有树形结构的物料数据)
          this.getAllocationNum();
        }
        //清空选中
        this.$refs.supplyAssign.clearSelection();
        //关闭弹框
        this.show_assignBom = false;
      },

      /* 判断指定物料数量是否已分配完 */
      judgeAllocationNum() {
        //判断数量设置不合法(为0或者为负数的)
        let numNoQualified = [];
        this.currentAlreadyAssign.forEach((item, index) => {
          if (parseInt(item.need_num) <= 0) {
            numNoQualified.push(item.product_info_code);
          }
        })
        if (numNoQualified.length > 0) {
          let mesStr = numNoQualified.join(",");
          this.$message({
            type: 'warning',
            message: "物料编号为【" + mesStr + "】的数量设置不能为0,请确认!",
            duration: this.elDuration
          })
          return false;
        }

        //判断当前分配数量超出可分配数量范围
        let exceedMaterialArr = []; //定义数量超出的(当前分配数量超出可分配数量范围)
        //循环所有物料
        this.tableData_material.forEach((itemI, indexI) => {
          //定义一个接受已分配的物料数量
          let alredyNum = 0;
          this.tableData_process.forEach((itemJ, indexJ) => {
            //有分配物料才执行
            if (itemJ.assignMaterial) {
              itemJ.assignMaterial.forEach((itemZ, indexZ) => {
                //根据id获取总的已分配数
                if (itemZ.bom_id == itemI.id) {
                  alredyNum = alredyNum + parseInt(itemZ.need_num);
                }
              })
            }
          })
          //判断所需数是否大于
          if (alredyNum > itemI.need_num) {
            exceedMaterialArr.push(itemI.product_info_code)
          }
        })
        //如果有超出的物料给出提示信息
        if (exceedMaterialArr.length > 0) {
          let mesStr = exceedMaterialArr.join(",");
          this.$message({
            type: 'warning',
            message: "物料编号为【" + mesStr + "】的数量超出未分配范围,请确认!",
            duration: this.elDuration
          })
          return false;
        }
        return true;
      },
      
      //删除工艺
      removeTech(data){
        this.tableData.forEach((item,index)=>{
          if(item.file_url==data.file_url){
            this.tableData.splice(index,1)
          }
        })
      },

      /* 获取未分配物料数据 */
      getAllocationNum() {
        //循环将所有工序的物料都获取
        let alreadyAllMaterial = [];
        this.tableData_process.forEach((itemI, indexI) => {
          itemI.assignMaterial.forEach((itemJ, indexJ) => {
            alreadyAllMaterial.push(itemJ);
          })
        })
        //获取物料未分配数量
        let noTreeBom = this.commonJsExtend.toNoTree(this.tableData_material, []); //获取无树形结构的数据
        noTreeBom.forEach((itemI, indexI) => {
          let alreadyNum = 0;
          alreadyAllMaterial.forEach((itemJ, indexJ) => {
            if (itemI.id == itemJ.bom_id) {
              alreadyNum = alreadyNum + parseInt(itemJ.need_num);
            }
          })
          //获取分配数量
          itemI.allot_num = alreadyNum; //已分配数量
          itemI.not_allot_num = eval(itemI.need_num - alreadyNum); //未分配数量
        })
        //转换为树形结构
        this.tableData_material = this.commonJsExtend.toTree(noTreeBom, 'parentid');
      },

      /* 显示新增工艺标准 */
      show_technologyfileBox() {
        this.show_technologyfile = true;
      },

      /* 隐藏工艺标准弹框 */
      hide_technologyfileBox() { //隐藏新增工艺标准
        this.show_technologyfile = false;
      },

      /* 提交审核 */
      saveSubmit(type) {
        //判断是否已选货品
        if (this.productBaseData.productName.trim() == '') {
          this.$message({
            type: 'warning',
            message: '请选择需要设置bom的货品',
            duration: this.elDuration
          })
          return false
        }
        //判断版本号是否已填
        if (this.productBaseData.productVersion.trim() == '') {
          this.$message({
            type: 'warning',
            message: '请填写当前bom的版本号',
            duration: this.elDuration
          })
          return false
        }
        //判断是否有进行成本价和总材料费用的计算(20221115 徐总确认 将费用控制放开)
        // if(!!!this.productBaseData.totalCostPrice.toString() || !!!this.productBaseData.totalMaterialCost.toString()
        // || !!!this.productBaseData.totalGrossCost.toString()){
        //   this.$message({
        //     type: 'warning',
        //     message: '请先计算相关费用',
        //     duration: this.elDuration
        //   })
        //   return false
        // }
        //判断物料是否已指定完毕(未写)
        //生产工序数据
        let processIdArr = []; //工序id数组
        let processMaterialArr = []; //工序指定合成物料数组
        this.tableData_process.forEach((item, index) => {
          processIdArr.push(item.id);
          if (item.mesMainBom == null) {
            processMaterialArr.push("null");
          } else {
            processMaterialArr.push(item.mesMainBom.id);
          }
        })
        //工序指定物料数据
        let processAssignData = [];
        this.tableData_process.forEach((itemI, indexI) => {
          itemI.assignMaterial.forEach((itemJ, indexJ) => {
            processAssignData.push(itemJ);
          })
        })
        //工艺标准数据
        let mesMainTech = [];
        //后端请求参数
        var data = {
          productId: this.currentProductInfo.id, //当前货品id
          bomVersion: this.productBaseData.productVersion, //当前bom的版本号
          bomVersionProperty: 0, //bom属性
          bomDate: this.productBaseData.productDate, //版本设置日期
          bom_audit_state: 0, //提交状态
          bom_create_user_id: this.UserInfo.user_id, //经办人Id
          bom_create_user_name: this.UserInfo.user_name, //经办人名称
          bom_audit_user_id: this.auditInfo.user_id, //审核人Id
          bom_audit_user_name: this.auditInfo.user_name, //审核人名称
          mesMainBom: JSON.stringify(this.tableData_material), //物料
          mesMainProcessIds: processIdArr.join(','), //工序id数据
          mesProcessMaterialIds: processMaterialArr.join(','), //工序指定合成物料id数组
          mesMainTech:JSON.stringify(this.tableData), //工艺标准
          mesBomProcessNum: JSON.stringify(processAssignData), //工序指定物料
          totalCostPrice:!!this.productBaseData.totalCostPrice?this.productBaseData.totalCostPrice:0,//总成本
          totalMaterialCost:!!this.productBaseData.totalMaterialCost?this.productBaseData.totalMaterialCost:0,//总材料费用
          totalGrossCost:!!this.productBaseData.totalGrossCost?this.productBaseData.totalGrossCost:0,//毛生产费用
          isChildCost:this.productBaseData.isChildCost?1:0,//是否含归属子级成本(0:否  1:是)
          levelAlone:this.productBaseData.levelAlone,//独有层级
          levelOwner:this.productBaseData.levelOwner,//归属层级
          assignNextVersionId:this.productBaseData.assignNextVersionId,//指定下级货品bom版本id
          assignNextVersionName:this.productBaseData.assignNextVersionName,//指定下级货品bom版本名称
          assignUpVersionId:this.productBaseData.assignUpVersionId,//指定上级货品id
          assignUpVersionName:this.productBaseData.assignUpVersionName,//指定上级货品名称
        }
        //加载loading框
        this.loading = this.commonJsExtend.customLoading("#addBomBox", 4, '物料信息提交中,请稍后...');
        // 审核请求
        api.addMesMainBom(data).then(res => {
          this.loading.close();
          // 判断后端返回接口
          if (res.code == 200) {
            // 判断操作方式
            if (type == 1) { //提交审核处理(需要审核)
              let submitData = {
                isSubmit: 1, //bom状态
                versionId: res.data, //货品id
                productName: this.currentProductInfo.name, //货品名称
              }
              //发送提交审核请求
              api.updateBomSubmit(submitData).then(res => {
                if (res.code == 200) {
                  this.SHOW_BILLSCREATEBOX(false);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            } else if (type == 2 || type == 3) { //2:保存并审核处理(需要审核) 3:确认(不需要审核)
              let submitData = {
                isSubmit: 4, //bom状态
                versionId: res.data, //版本id
                productName: this.currentProductInfo.name, //货品名称
              }
              //发送提交审核请求
              api.updateBomStatusYes(submitData).then(res => {
                if (res.code == 200) {
                  this.SHOW_BILLSCREATEBOX(false);
                } else {
                  this.$message({
                    type: 'error',
                    message: res.message,
                    duration: this.elDuration
                  })
                }
              })
            }
          } else {
            this.$message({
              type: 'error',
              message: res.message,
              duration: this.elDuration
            })
          }
        })
      },

      /* 工序表格删除数据 */
      delectProcess(index, id) {
        // 判断当前操作界面
        if (this.operationState == 0) { //新增界面
          // 判断当前工序是否有指定物料
          if (this.tableData_process[index].assignMaterial.length > 0) {
            //获取物料无树形结构数据
            let noTreeBom = this.commonJsExtend.toNoTree(this.tableData_material, []);
            //循环修改物料数据分配数量
            this.tableData_process[index].assignMaterial.forEach((itemI, indexI) => {
              noTreeBom.forEach((itemJ, indexJ) => {
                if (itemI.bom_id == itemJ.id) {
                  //减少已分配量
                  itemJ.allot_num = eval(itemJ.allot_num - parseInt(itemI.need_num));
                  //增加未分配量
                  itemJ.not_allot_num = eval(itemJ.not_allot_num + parseInt(itemI.need_num));
                }
              })
            })
            //转换为树形结构
            this.tableData_material = this.commonJsExtend.toTree(noTreeBom, 'parentid');
          }
          //删除工序
          this.tableData_process.splice(index, 1);
        } else if (this.operationState == 1) { //修改界面
          this.$confirm('删除工序后,工序已指定物料需重新分配,您确认继续吗?', '提示', {
            confirmButtonText: '确定',
            cancelButtonText: '取消',
            type: 'warning'
          }).then(() => {
            let deleteData = {
              processId: id,
              versionId: this.bomProductData.listVersion.id
            }
            api.deleteBomProcessByProcessId(deleteData).then(res => {
              if (res.code == 200) {
                //调用查询方法
                this.getBomInfoByProductId(this.currentProductInfo.id, 3, 1);
              } else {
                this.$message({
                  type: 'error',
                  message: res.message,
                  duration: this.elDuration
                })
              }
            })
          }).catch(() => {
            this.$message({
              type: 'info',
              message: '已取消删除'
            });
          });
        }
      },

      /*初始化列表显示字段数据 */
      initListShowFiledData() {
        //获取界面显示数组
        this.showListFiledArr = this.commonJsExtend.initListShowFiledData('bomMaterialListArr', this
            .bomMaterialListFileData)
          .showListFiledArr;
        //获取控制数组
        this.showListFiledData = this.commonJsExtend.initListShowFiledData('bomMaterialListArr', this
            .bomMaterialListFileData)
          .showListFiledData;
      },

      /* 获取当前需要显示的列表字段 */
      getCurShowFiledArr() {
        //将数据存入cookie
        this.showListFiledArr = this.commonJsExtend.getCurShowFiledArr('bomMaterialListArr', this.showListFiledData);
      },

      /*初始化列表显示字段数据 */
      initProcessListShowFiledData() {
        //设置默认字段
        this.showProcessListFiledArr = [];
        // 判断cookie是否有值
        if (getCookie('bomProcessListArr')) {
          this.showProcessListFiledArr = getCookie('bomProcessListArr');
        } else {
          //默认全部加载
          this.bomProcessListFileData.forEach((item, index) => {
            this.showProcessListFiledArr.push(item.value);
          })
        }
        //设置可显示字段
        this.showProcessListFiledData = this.bomProcessListFileData;
        //判断是否默认勾选
        this.showProcessListFiledData.forEach((item, index) => {
          if (this.showProcessListFiledArr.includes(item.value)) {
            item.is_show = true;
          }
          //判断是否开启旧物料编码
          if (item.value == 14) {
            if (this.companyparamlist.param345 == "1") { //不启用
              this.showListFiledData.splice(index, 1);
            }
          }
        })
      },

      childerCallback(arr,num){
        arr.forEach((item,index)=>{
          if(!!item.children&&item.children.length>0){
            this.childerCallback(item.children,num+1)
          }
          else{
            this.levelAloneNum.push(num)
          }
        })
      },

      /*获得独有层级信息*/
      uniqueHierarchy(){
        // console.log(this.watchNum)
        if(this.watchNum>1){
          this.productBaseData.totalCostPrice=''
          this.productBaseData.totalMaterialCost=''
          this.productBaseData.totalGrossCost=''
        }
        this.levelAloneNum=[]
        if(!!this.tableData_material && this.tableData_material.length>0){
         this.childerCallback(this.tableData_material,1)
        }
        if(this.levelAloneNum.length>0){
          this.productBaseData.levelAlone=Math.max.apply(null, this.levelAloneNum)
        }
        this.watchNum=this.watchNum+1
      },

      /* 获取当前需要显示的列表字段 */
      getProcessCurShowFiledArr() {
        //循环前清空数据
        this.showProcessListFiledArr = [];
        //循环获取已勾选的数据
        this.showProcessListFiledData.forEach((item, index) => {
          //判断是否勾选
          if (item.is_show) {
            this.showProcessListFiledArr.push(item.value);
          }
        })
        //将数据存储到cookie
        setCookie("bomProcessListArr", this.showProcessListFiledArr, 15);
      },
      // 工序导出相关
      processExPort(){
        console.log(this.productBaseData)
        let processList=[]
        this.tableData_process.forEach((item,index)=>{
          let process={
            processName:item.name,
            totalCost:item.totalCost,
            totalMaterialCost:item.totalMaterialCost,
            totalProcessingCost:item.totalProcessingCost,
            total_labour_cost:item.total_labour_cost,
            dept_name:item.dept_name
          }
          processList.push(process)
        })
        let data={
          id:this.productBaseData.id,
          bomProcessExportList:JSON.stringify(processList),
        }
        // 请求前加载loading框
        this.loading = this.commonJsExtend.customLoading("#billExportBox", 4, 'bom工序导出中,请稍候...');
        data.fields = this.bomProcessExportKeys.join(','); //需要导出的字段名
        req.exportBomProcessList(data).then(res=>{
           if (res.code == 200 && res.data) {
             let filePathArr = [];
             let name = '';
             filePathArr = res.data.split('//')
             name = decodeURI(filePathArr[filePathArr.length - 1])
             //设置操作类型为导出excle
             this.operate = 1;
             //下载文件
             this.downloadFile(res.data, name, 1)
             //关闭导出弹框
             this.loading.close();
           } else {
             this.$message({
               type: 'error',
               message: res.message,
               duration: this.elDuration
             })
             this.loading.close();
           }
        })
      },
      /* 下载文件或打开pdf文件 */
            downloadFile(href, name) {
              if (this.operate == 2) { //为pdf文件时
                let filePreViewData = {
                  file_path: href,
                  name
                }
                this.showFilePreView(filePreViewData)
              } else if (this.operate == 1) { //为其他文件时
                const link = document.createElement("a");
                link.style.display = 'none';
                link.href = baseUrl + href;
                link.download = name;
                document.body.appendChild(link);
                link.click();
              }
            },
      /* 关闭新增弹框 */
      colse_createBox() {
        this.SHOW_BILLSCREATEBOX(false)
      }
    },
    components: {
      productList: () => import('@/components/commComponent/list/list_product.vue'),
      materialAdd,
      // productList,
      technologyList,
      formTechnologyFile,
      listBomVersion,
      filePreview
    },
    filters: {
      getProcessMaterialClaaify(val) {
        let result = "";
        if (val.length == 0) {
          result = "未设置物料"
        }
        return result;
      },
    }
  }
</script>

<style>
  /* 工序指定物料隐藏全选框 */
  .assignBomBox .el-table__header .el-checkbox {
    display: none !important
  }
</style>
<style lang="less" scoped>
  /* 选项卡外框样式 */
  .productplanTab {
    padding: 0 20px;

    // border: 1px solid red;
    // 公共头部样式
    .createtab_head {
      padding-bottom: 10px;
      // border: 1px solid black;
    }

    // 公共表格样式
    .createtab_table {
      height: 52vh;
      // border: 1px solid black;
    }
  }

  //计算按钮样式
  .calculate_span{
    cursor: pointer;
    color: @theme;
  }

  // 客户设置bom弹框
  .customerSetBox {
    height: 30vh;
  }
</style>
<style lang="less" src="@/less/production/process_assign.less"></style>
